function Carier() {


    return (
        <>
            Carier
        </>
    )

}

export default Carier;