import { useEffect } from "react";

function Facebook() {
    const FacebookCallback = (response) => {
        if (response.status === 'connected') {
            console.log('Logged in and authenticated');
        } else {
            console.log('User not authenticated');
        }
    }
    useEffect(() => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: '7392788087484844',
                cookie: true,
                xfbml: true,
                version: 'v19.0'
            });

            window.FB.getLoginStatus((response) => {
                FacebookCallback(response);
            });
        };
        window.FB.login(FacebookCallback, { scope: 'public_profile,email' })
    }, [])

    return ""
}
export default Facebook;