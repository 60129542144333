import axios from "axios";
import { useEffect, useRef, useState, useCallback } from "react";
import { Dates, Declare, Root, Urlgenerator } from "../libs/element";
import { useNavigate, useParams, useLocation } from "react-router-dom";

function Category({ categories }) {
    const bounding = useRef(null);
    const location = useLocation()
    const { id } = useParams()
    const navigate = useNavigate();
    const [Limit, setLimit] = useState(11);
    const [Status, setStatus] = useState(false);
    const [Category, setCategory] = useState([])

    const [Writers, setWriters] = useState([])
    const Select = async () => {
        const result = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post&conditions=category=${id}&page=1&limit=${Limit}&order=created_date DESC`)).data
        const [response] = await Promise.all([result]);
        setCategory(response.data);
        setStatus(false)

        if (parseInt(id) === 8) {
            const result = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=user&conditions=type=5 OR type=10`)).data
            const [response] = await Promise.all([result]);
            setWriters(response.data);
        }
    }
    const Routers = (category, title, id) => {
        if (category !== undefined) {
            const result = categories.filter((f) => { return (f.id === category) })
            navigate(`/${Urlgenerator({ data: result[0]["category"] })}/${Urlgenerator({ data: title })}/${id}`)
        }
    }
    const observer = useRef();
    const InfinityScroll = useCallback(node => {
        if (Status) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setStatus(true);
                setLimit(Limit + 10)
            }
        });
        if (node) observer.current.observe(node);
    }, [Status]);
    useEffect(() => {
        Select()
    }, [id, Limit])



    const compareDateTime = (a, b) => {
        const dateA = new Date(`${a.created_date} ${a.created_time}`);
        const dateB = new Date(`${b.created_date} ${b.created_time}`);
        if (dateA > dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
    };

    return (
        <>
            {

                parseInt(id) === 8 ?
                    <section>
                        {
                            (Writers || []).map((d, x) => {
                                return (
                                    <div className="ps-writers-container">
                                        <div className="ps-writers-content">
                                            <div style={{ width: "150px" }}>
                                                <div className="ps-writers-photo" style={{ backgroundImage: `url(${d.photo})` }}></div>
                                            </div>
                                            <div className="ps-writers-info">
                                                <div>
                                                    <div className="ps-writers-name">{d.name} {d.surname}</div>
                                                    <div className="ps-writers-email">{d.email}</div>
                                                </div>
                                            </div>
                                            <div className="ps-writers-page" hidden onClick={() => { navigate("/yazar-yazilari/turgut-karaboyun/10") }}>
                                                Yazara ait tüm yazılar <i className="fa-solid fa-arrow-right ms-2"></i>
                                            </div>
                                        </div>
                                        <div className="ps-writers-posts p-3">
                                            <div className="row">
                                                {
                                                    (Category || []).filter((f) => { return (f.created_by === d.id && parseInt(f.category) === parseInt(id)) }).slice(0, 3).map((a, y) => {
                                                        return (
                                                            <div className="col-lg-4 post-list-activity ps-p-0" key={`post-activity-${a.id}-list-${x}`} onClick={() => { Routers(a.category, a.title, a.post_id) }}>
                                                                <div className="ps-post-list-container">
                                                                    <div className="ps-post-list-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: a.media } })})` }}>
                                                                    </div>
                                                                    <div className="ps-post-list-content">

                                                                        <div className="ps-post-category-date" style={{ width: "145px" }}>
                                                                            {Dates({ data: a.created_date })}
                                                                        </div>
                                                                        <div className="ps-post-category-title">
                                                                            {a.title}
                                                                        </div>
                                                                        <div className="ps-post-list-icon">
                                                                            <div className="me-3">
                                                                                <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                                            </div>
                                                                            <div className="me-3">
                                                                                <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                                            </div>
                                                                            <div className="me-3">
                                                                                <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </section>
                    :
                    <section>
                        <div className="row m-0">
                            <div className="col-lg-12 p-0 mt-3">
                                {
                                    (Category || []).sort(compareDateTime).slice(0, Limit).map((d, x) => {
                                        return (
                                            <div ref={x === Category.length - 1 ? InfinityScroll : null} className="ps-post-category-container" key={`category-list-${d.id}-list-${x}`} onClick={() => { Routers(d.category, d.title, d.post_id) }}>

                                                <div className="ps-post-category-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                                <div className="ps-post-category-content">
                                                    <div className="ps-post-category-title">
                                                        {d.title}
                                                    </div>
                                                    <div className="ps-post-category-text">
                                                        {d.description}
                                                    </div>
                                                    <div className="ps-post-category-icon">
                                                        <div className="d-flex align-items-center h-30px">
                                                            <div className="me-3">
                                                                <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                            </div>
                                                            <div className="me-3">
                                                                <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                            </div>
                                                            <div className="me-3">
                                                                <i className="fa-solid fa-share-nodes me-1 fs-14" ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
            }


        </>
    )
}

export default Category;