import { Route, Routes, useLocation, useMatch, useNavigate } from "react-router-dom";
import Category from "./pages/category";
import Detail from "./pages/detail";
import Index from "./pages";

import "./assets/css/bootstrap.min.css"
import "./assets/css/responsive.css"
import "./assets/css/ps.style.css"

import "./assets/fontawsome/css/all.css"
import "./assets/fontawsome/css/brands.css"
import "./assets/fontawsome/css/fontawesome.css"
import "./assets/fontawsome/css/regular.css"
import "./assets/fontawsome/css/solid.css"
import "./assets/fontawsome/css/svg-with-js.css"
import "./assets/fontawsome/css/v4-font-face.css"
import "./assets/fontawsome/css/v4-shims.css"
import "./assets/fontawsome/css/v5-font-face.css"

import { Footer, Header } from "./libs/module";
import { useEffect, useState } from "react";
import axios from "axios";
import { Dates, Declare, Root, Unique } from "./libs/element";
import { Urlgenerator } from "./libs/element";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import Documentation from "./pages/document";
import Aboutus from "./pages/about";
import Tags from "./pages/tags";
import Contact from "./pages/contact";
import Carier from "./pages/carier";
import Login from "./pages/login";
import Writers from "./pages/writers";
import Facebook from "./pages/facebook";
import Error404 from "./pages/404";

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const [CategoryData, setCategoryData] = useState([])
  const Select = async () => {
    const response = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post_category&page=1&limit=10`)).data
    setCategoryData(response.data);
  }
  useEffect(() => {
    Select()
  }, [])

  const [SwitzerlandData, setSwitzerlandData] = useState([])
  const [TopsData, setTopsData] = useState([])
  const [ActivityData, setActivityData] = useState([])
  const [GlobalData, setGlobalData] = useState([])
  const [AdsenseData, setAdsenseData] = useState([])

  const Sidebar = async () => {


    const switzerland = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post&page=2&limit=6&conditions=category=1&order=created_date DESC`)).data;
    const [switzerlandresponse] = await Promise.all([switzerland]);
    setSwitzerlandData(switzerlandresponse.data)

    const postIds = switzerlandresponse.data.map(item => item.id).join(",")
    const tops = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post&page=1&conditions=id IN (${postIds})`)).data;
    const [topsresponse] = await Promise.all([tops]);
    setTopsData(topsresponse.data)

    const id = location.pathname.split("/")[2]
    if (location.pathname === "/") {
      const activity = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post&page=2&limit=6&conditions=category=7&order=created_date DESC`)).data;
      const [activityresponse] = await Promise.all([activity]);
      setActivityData(activityresponse.data)

    } else {
      const Getid = () => {
        switch (parseInt(id)) {
          case 1: return 1
          case 2: return 3
          case 3: return 8
          case 4: return 5
          case 5: return 1
          case 6: return 5
          case 7: return 3
          case 8: return 8
          case 12: return 5
          default: return 1
        }
      }
      const globa = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post&page=2&limit=6&conditions=category=${Getid()}&order=created_date DESC`)).data;
      const [globaresponse] = await Promise.all([globa]);
      setGlobalData(globaresponse.data)



    }

    const adsense = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=ads`)).data;
    const [adsenseresponse] = await Promise.all([adsense]);
    setAdsenseData(adsenseresponse.data)
  }
  const Routers = (category, title, id) => {
    if (category !== undefined) {
      const result = CategoryData.filter((f) => { return (f.id === category) })
      navigate(`/${Urlgenerator({ data: result[0]["category"] })}/${Urlgenerator({ data: title })}/${id}`)
    }
  }
  useEffect(() => {
    Sidebar();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname])
  useEffect(() => {
    const uniqueid = Unique({ data: 5 });
    if (!window.localStorage.getItem("ps-guest")) {
      const guest = {
        guest_id: uniqueid,
        guest_email: `guest-${uniqueid}-email`,
        guest_username: `guest-${uniqueid}-username`,
        guest_remember: false
      }
      window.localStorage.setItem("ps-guest", JSON.stringify(guest))
    }
  }, [])


  const Adsense = ({ page, position }) => {
    const result = (AdsenseData || []).filter((f) => { return (f.page === page && f.position === position) })
    if (result.length > 0) {
      return JSON.parse(result[0].media)
    }
    else {
      return [];
    }
  }
  const [CurrencyData, setCurrencyData] = useState([])
  const GetCurrency = async () => {
    const currency = (await axios.get(`${Root({ data: { type: "api" } })}?params=currency`)).data;
    const result = JSON.parse(currency.data);
    setCurrencyData(result.Currency)
  }
  useEffect(() => {
    GetCurrency()
  }, [])




  const AuthUrl = () => {
    var data = [
      "/iletisim",
      "/kunye",
      "/hakkimizda",
      "/belgeler/kisisel-verilerin-korunmasi",
      "/abone/hesap-olustur",
      "/abone/giris-yap"
    ]
    const result = data.filter((f) => { return (f === location.pathname) })
    if (result.length > 0) {
      return false
    }
    else {
      return true
    }
  }



  const [Terms, setTerms] = useState(false)
  const [Email, setEmail] = useState("")
  const [Alert, setAlert] = useState(200)
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const Subscriber = () => {
    if (Email === "") {
      setAlert(300)
    } else {
      if (!validateEmail(Email)) {
        setAlert(301)
      }
      else {
        axios.get(`${Root({ data: { type: "api" } })}?params=select&table=subscribers&conditions=email='${Email}'`).then((data) => {
          const result = data.data.data
          if (result.length > 0) {
            setAlert(302)
          }
          else {

            if (!Terms) {
              setAlert(303)
            }
            else {
              axios.post(Root({ data: { type: "api" } }), {
                params: "insert",
                data: {
                  table: "subscribers",
                  value: {
                    email: Email,
                  }
                },
              })
              setAlert(400)
            }

          }
        })
      }
    }
  }



  return (
    <>

      <Header categories={CategoryData} adsense={AdsenseData} topsdata={TopsData} currency={CurrencyData} />
      <div >
        <main>
          <div className={AuthUrl() ? "container" : ""}>
            <div className="row m-0">
              <div className={`${AuthUrl() ? "col-lg-9" : "col-lg-12"}`}>
                <Routes>
                  <Route path="/" element={<Index categories={CategoryData} adsense={AdsenseData} />} />
                  <Route path="/:category/:name/:id" element={<Detail categories={CategoryData} adsense={AdsenseData} />} />
                  <Route path="/yazar-yazilari/:name/:id" element={<Writers categories={CategoryData} />} />
                  <Route path="/:category/:id" element={<Category categories={CategoryData} />} />
                  <Route path="/belgeler/:rules" element={<Documentation />} />
                  <Route path="/hakkimizda" element={<Aboutus />} />
                  <Route path="/kunye" element={<Tags />} />
                  <Route path="/iletisim" element={<Contact />} />
                  <Route path="/kariyer" element={<Carier />} />
                  <Route path="/abone/:rules" element={<Login />} />
                  <Route path="/facebook" element={<Facebook />} />
                  <Route path="*" element={<Error404 />} />
                </Routes>
              </div>
              {
                AuthUrl() ?
                  <div className="col-lg-3 d-sm-none d-lg-block" >

                    {/*Banner */}
                    <section className="mb-2" >
                      <Swiper loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }} className="slider-arrows mb-3">
                        {
                          Adsense({ page: "home", position: "sidebar_1" }).map((d, x) => {
                            return (
                              <SwiperSlide key={`banner-${d.position}-list-${x}`} style={{ padding: "0px" }}>
                                <div className="ps-post-banner p-0">
                                  <a href={`${d.url}`} target="_blank">
                                    <img src={`${Root({ data: { type: "banner" } })}${d.blob}`} style={{ width: "100%", height: "300px" }} />
                                  </a>
                                </div>
                              </SwiperSlide>
                            )
                          })
                        }
                      </Swiper>
                    </section>

                    {/*Sosyal Medya */}
                    <section className="mb-2" hidden>
                      <div className="mt-3 mb-2">
                        <div className="section-title section-title-four">
                          <div className="title">Instagram</div>
                          <div className="section-title-line"></div>
                        </div>
                      </div>
                      <div className="ps-instagram-content">
                        <div data-mc-src="88bbf0e4-d407-4983-a50e-fa937f0b4a16#instagram" style={{ marginTop: "-30px" }}></div>
                      </div>
                    </section>

                    <section className="mb-2" style={{ paddingLeft: "10px", paddingRight: "10px" }} >
                      <div className="ps-socialmedia" >
                        <div className="row">
                          <div className="col-lg-12 mb-0 p-1">
                            <a type="button" className="btn ps-button ps-button-facebook d-flex align-items-center justify-content-center" target="_blank" href="https://www.facebook.com/PusulaSwiss">
                              <i className="fa-brands fa-square-facebook me-2"></i>
                              Facebook
                            </a>
                          </div>
                          <div className="col-lg-12 mb-0 p-1">
                            <a type="button" className="btn ps-button ps-button-instagram d-flex align-items-center justify-content-center" target="_blank" href="https://www.instagram.com/pusula.swiss/">
                              <i className="fa-brands fa-instagram me-2"></i>
                              Instagram
                            </a>
                          </div>
                          <div className="col-lg-12 mb-0 p-1">
                            <a type="button" className="btn ps-button ps-button-youtube d-flex align-items-center justify-content-center" target="_blank" href="https://www.youtube.com/@Pusulaswiss">
                              <i className="fa-brands fa-square-youtube me-2"></i>
                              Youtube
                            </a>
                          </div>
                        </div>
                      </div>
                    </section>

                    {/*İsviçre Haberleri */}
                    <section className="mb-2" hidden={location.pathname !== "/" ? true : false}>

                      <div className="mt-3 mb-2">
                        <div className="section-title section-title-four">
                          <div className="title"> İsviçre Haberleri</div>
                          <div className="section-title-line"></div>
                        </div>
                      </div>
                      {
                        (SwitzerlandData || []).slice(0, 10).map((d, x) => {
                          return (
                            <div className="shape-last" key={`switzerland-data-${d.id}-list-${x}`}>
                              <div className="ps-sidebar-container mb-0" onClick={() => { Routers(d.category, d.title, d.post_id) }}>
                                <div className="ps-sidebar-content mb-0">
                                  <div>
                                    <div className="ps-sidebar-title mt-3">
                                      {d.title}
                                    </div>
                                  </div>
                                </div>
                                <div className="w-120px">
                                  <div className="ps-sidebar-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                  </div>
                                </div>
                              </div>
                              <div className="ps-shape"></div>
                            </div>
                          )
                        })
                      }

                    </section>

                    {/*Banner */}
                    <section className="mb-2" hidden={location.pathname !== "/" ? true : false}>
                      <Swiper loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }} className="slider-arrows">
                        {
                          Adsense({ page: "home", position: "sidebar_2" }).map((d, x) => {
                            return (
                              <SwiperSlide key={`banner-${d.position}-list-${x}`}>
                                <div className="ps-post-banner">
                                  <a href={`${d.url}`} target="_blank">
                                    <img src={`${Root({ data: { type: "banner" } })}${d.blob}`} style={{ width: "100%", height: "300px" }} />
                                  </a>
                                </div>
                              </SwiperSlide>
                            )
                          })
                        }
                      </Swiper>
                    </section>

                    {/*En Çok Okunanalar */}
                    <section className="mb-2" hidden={location.pathname !== "/" ? true : false}>

                      <div className="mt-2 mb-2">
                        <div className="section-title section-title-four">
                          <div className="title"> En Çok Okunanalar</div>
                          <div className="section-title-line"></div>
                        </div>
                      </div>

                      {
                        (TopsData || []).slice(0, 10).map((d, x) => {
                          return (
                            <div className="shape-last" key={`top-read-data-${d.id}-list-${x}`}>
                              <div className="ps-sidebar-container mb-0" onClick={() => { Routers(d.category, d.title, d.post_id) }}>
                                <div className="ps-sidebar-content mb-0">
                                  <div>
                                    <div className="ps-sidebar-title mt-3">
                                      {d.title}
                                    </div>
                                  </div>
                                </div>
                                <div className="w-120px">
                                  <div className="ps-sidebar-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                  </div>
                                </div>
                              </div>
                              <div className="ps-shape"></div>
                            </div>
                          )
                        })
                      }

                    </section>

                    {/*E-Bülten */}
                    <section className="mb-2" hidden={location.pathname !== "/" ? true : false}>
                      <div className="alert alert-danger" hidden={Alert === 300 ? false : true}>
                        E-Posta Adresi Boş Olamaz
                      </div>
                      <div className="alert alert-danger" hidden={Alert === 301 ? false : true}>
                        E-Posta Biçimi Hatalı
                      </div>
                      <div className="alert alert-danger" hidden={Alert === 302 ? false : true}>
                        Bu E-Posta Zaten Sistemimizde Var
                      </div>
                      <div className="alert alert-danger" hidden={Alert === 303 ? false : true}>
                        Şartlarımızı Kabul Etmediniz.
                      </div>
                      <div className="alert alert-success" hidden={Alert === 400 ? false : true}>
                        Bu E-Posta Bültenimize Kayıt Oldunuz.
                      </div>
                      <div className="ps-newsletter">
                        <h4 className="title">Bülten</h4>
                        <p>Gündemdeki önemli gelişmeler ve etkinliklerden haberdar olmak için ücretsiz E-Bülten aboneliği yaptırabilirsiniz.</p>
                        <div>
                          <div className="mb-2">
                            <input type="text" placeholder="Mail Adresiniz" className="ps-input mb-2" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
                          </div>
                          <div className="mb-2">
                            <button type="button" className="btn ps-button ps-button-white" onClick={() => { Subscriber() }}>
                              Abone Ol
                            </button>
                          </div>
                          <div className="mb-2">
                            <div className="form-check">
                              <input type="checkbox" className="form-check-input" id="checkbox" value="false" checked={Terms} onChange={(e) => { setTerms(e.target.checked) }} />
                              <label htmlFor="checkbox">
                                <a href="/belgeler/abonelik-hakkinda" target="_blank" className="checkbox-label" onClick={() => { setTerms(true) }}>Şartlar</a>ınızı Kabul Ediyorum
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    {/*Gezi Rehberi */}
                    <section className="mb-2" hidden={location.pathname !== "/" ? true : false}>

                      <div className="mt-2 mb-2">
                        <div className="section-title section-title-four">
                          <div className="title">Gezi Rehberi</div>
                          <div className="section-title-line"></div>
                        </div>
                      </div>

                      {
                        (ActivityData || []).slice(0, 10).map((d, x) => {
                          return (
                            <div className="shape-last" key={`activity-data-${d.id}-list-${x}`}>
                              <div className="ps-sidebar-container mb-0" onClick={() => { Routers(d.category, d.title, d.post_id) }}>
                                <div className="ps-sidebar-content mb-0">
                                  <div>
                                    <div className="ps-sidebar-title mt-3">
                                      {d.title}
                                    </div>
                                  </div>
                                </div>
                                <div className="w-120px">
                                  <div className="ps-sidebar-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                  </div>
                                </div>
                              </div>
                              <div className="ps-shape"></div>
                            </div>
                          )
                        })
                      }

                    </section>

                    {/*Banner */}
                    <section className="mb-2" hidden={location.pathname !== "/" ? true : false}>
                      <Swiper loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }} className="slider-arrows">
                        {
                          Adsense({ page: "home", position: "sidebar_3" }).map((d, x) => {
                            return (
                              <SwiperSlide key={`banner-${d.position}-list-${x}`}>
                                <div className="ps-post-banner">
                                  <a href={`${d.url}`} target="_blank">
                                    <img src={`${Root({ data: { type: "banner" } })}${d.blob}`} style={{ width: "100%", height: "648px" }} />
                                  </a>
                                </div>
                              </SwiperSlide>
                            )
                          })
                        }
                      </Swiper>
                    </section>

                    {/*Banner --> HOME */}
                    <section className="mb-2" hidden={location.pathname === "/" ? true : false}>
                      <Swiper loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }} className="slider-arrows">
                        {
                          Adsense({ page: "detail", position: "sidebar_1" }).map((d, x) => {
                            return (
                              <SwiperSlide key={`banner-${d.position}-list-${x}`}>
                                <div className="ps-post-banner">
                                  <a href={`${d.url}`} target="_blank">
                                    <img src={`${Root({ data: { type: "banner" } })}${d.blob}`} style={{ width: "100%", height: "300px" }} />
                                  </a>
                                </div>
                              </SwiperSlide>
                            )
                          })
                        }
                      </Swiper>
                    </section>

                    {/*En Son Haberler --> HOME */}
                    <section className="mb-2" hidden={location.pathname === "/" ? true : false}>
                      <div>
                        <div className="section-title section-title-four">
                          <div className="title"> En Son Haberler</div>
                          <div className="section-title-line"></div>
                        </div>
                      </div>

                      {
                        (SwitzerlandData || []).slice(0, 10).map((d, x) => {
                          return (
                            <div className="shape-last" key={`global-data-data-${d.id}-list-${x}`}>
                              <div className="ps-sidebar-container mb-0" onClick={() => { Routers(d.category, d.title, d.post_id) }}>
                                <div className="ps-sidebar-content mb-0">
                                  <div>
                                    <div className="ps-sidebar-title">
                                      {d.title}
                                    </div>
                                  </div>
                                </div>
                                <div className="w-120px">
                                  <div className="ps-sidebar-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                  </div>
                                </div>
                              </div>
                              <div className="ps-shape"></div>
                            </div>
                          )
                        })
                      }

                    </section>

                    {/*Banner --> HOME */}
                    <section className="mb-2" hidden={location.pathname === "/" ? true : false}>
                      <Swiper loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }} className="slider-arrows">
                        {
                          Adsense({ page: "detail", position: "sidebar_2" }).map((d, x) => {
                            return (
                              <SwiperSlide key={`banner-${d.position}-list-${x}`}>
                                <div className="ps-post-banner">
                                  <a href={`${d.url}`} target="_blank">
                                    <img src={`${Root({ data: { type: "banner" } })}${d.blob}`} style={{ width: "298px", height: "648px" }} />
                                  </a>
                                </div>
                              </SwiperSlide>
                            )
                          })
                        }
                      </Swiper>
                    </section>

                  </div>
                  :
                  <div></div>
              }
            </div>
          </div>
        </main>
      </div>


      <Footer categories={CategoryData} currency={CurrencyData} />


    </>
  );
}

export default Layout;
