import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Dates, Declare, Root, Urlgenerator } from "../libs/element";
import { useCallback, useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';

function Index({ categories, adsense }) {
    const sliderRef = useRef(null);
    const navigate = useNavigate()
    const [SpeacialData, setSpeacialData] = useState([])
    const [AgendaData, setAgendaData] = useState([])
    const [CultureData, setCultureData] = useState([])
    const [ActivityData, setActivityData] = useState([])
    const [VideoData, setVideoData] = useState([])
    const [SlideData, setSlideData] = useState([])
    const [SwitzerlandData, setSwitzerlandData] = useState([])
    const [InterviewData, setInterviewData] = useState([])

    const Select = async () => {
        const categoryLimits = {
            0: 10, // Slider
            1: 10, // İsviçre Haberleri
            2: 10, // Özel Haberler
            3: 10, // Gündem Haberleri
            4: 10, // Söyleyişi
            5: 10, // Kültür Sanat Haberleri
            6: 6, // Etkinlik Haberleri
            12: 6 // Video Haberleri
        };
        const categoryParams = encodeURIComponent(JSON.stringify(categoryLimits));
        const result = (await axios.get(`${Root({ data: { type: "api" } })}?params=category&table=post&page=1&categories=${categoryParams}&order=created_date DESC`)).data
        const [response] = await Promise.all([result]);

        console.log(result.data[2])
        setSwitzerlandData(result.data[1]) // Video Haberleri
        setSpeacialData(result.data[2])  // Özel Haberler
        setAgendaData(result.data[3])  // Gündem Haberleri
        setInterviewData(result.data[4])
        setCultureData(result.data[5])  // Kültür Sanat Haberleri
        setActivityData(result.data[6])  // Etkinlik Haberleri
        setVideoData(result.data[12]) // Video Haberleri
        setSlideData(result.data[1])



    }
    const Routes = (category, title, id) => {
        if (category !== undefined) {
            const result = categories.filter((f) => { return (f.id === category) })
            navigate(`${Urlgenerator({ data: result[0]["category"] })}/${Urlgenerator({ data: title })}/${id}`)
        }
    }
    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);
    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);
    useEffect(() => {
        Select()
    }, [])
    const Adsense = ({ page, position }) => {
        const result = (adsense || []).filter((f) => { return (f.page === page && f.position === position) })
        if (result.length > 0) {
            return JSON.parse(result[0].media)
        }
        else {
            return [];
        }
    }
    const Slider = ({ data }) => {

        return (
            <>
                <Swiper
                    ref={sliderRef}
                    loop={true}
                    modules={[Autoplay]}
                    autoplay={{ delay: 2500 }}
                    className="slider-arrows"
                >
                    {
                        (data || []).slice(0, 8).sort(compareDateTime).map((d, x) => {
                            return (
                                <SwiperSlide key={`post-slide-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                    <div className="ps-post-big-container bg-none">
                                        <div className="ps-post-big-image h-410px" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                        <div className="ps-post-big-content text-center border-none bg-none">
                                            <div className="ps-post-list-date">
                                                {Dates({ data: d.created_date })}
                                            </div>
                                            <div className="ps-post-big-title text-start">
                                                {d.title}
                                            </div>
                                            <div className="ps-post-big-text text-start">
                                                {d.description}
                                            </div>
                                            <div className="ps-post-big-icon">
                                                <div className="me-3">
                                                    <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                </div>
                                                <div className="me-3">
                                                    <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                </div>
                                                <div className="me-3">
                                                    <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                    <div className="prev-arrow" onClick={handlePrev} >
                        <i className="fa-solid fa-angle-left text-white"></i>
                    </div>
                    <div className="next-arrow" onClick={handleNext} >
                        <i className="fa-solid fa-angle-right text-white"></i>
                    </div>
                </Swiper>

            </>
        )
    }
    const compareDateTime = (a, b) => {
        const dateA = new Date(`${a.created_date} ${a.created_time}`);
        const dateB = new Date(`${b.created_date} ${b.created_time}`);
        if (dateA > dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
    };

    const Section_head = ({ title, url }) => {
        return (
            <>
                <div className="section-title section-title-four mb-3" onClick={() => { navigate(url) }}>
                    <div className="title">{title}</div>
                    <div className="section-title-line"></div>
                    <i className="fa-solid fa-square-up-right fs-2"></i>
                </div>
            </>
        )
    }
    const Section_banner = ({ section_type }) => {

        return (
            <>
                { /* Desktop */}
                <section className="d-lg-block d-sm-none">
                    <Swiper loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }}>
                        {
                            Adsense({ page: "home", position: section_type }).map((d, x) => {
                                return (
                                    <SwiperSlide key={`banner-${d.position}-list-${x}`} style={{ height: "128.25px" }}>
                                        <div className="ps-post-banner">
                                            <a href={`${d.url}`} target="_blank">
                                                <img src={`${Root({ data: { type: "banner" } })}${d.blob}`} style={{ width: "100%", height: "118px" }} alt="pusula-swiss-banner" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </section>

                { /* Mobile */}
                <section className="d-lg-none d-sm-block mb-3">
                    <Swiper loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }}>
                        {
                            Adsense({ page: "home", position: "sidebar_1" }).map((d, x) => {
                                return (
                                    <SwiperSlide key={`banner-${d.position}-list-${x}`}>
                                        <div className="ps-post-banner">
                                            <a href={`${d.url}`} target="_blank">
                                                <img src={`${Root({ data: { type: "banner" } })}${d.blob}`} style={{ width: "100%", height: "auto" }} alt="pusula-swiss-banner" />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </section>
            </>
        )
    }
    const Section_slider = ({ slider_data, short_data }) => {

        return (
            <>
                { /* Desktop */}
                <section className="d-lg-block d-sm-none">
                    <div className="row">
                        <div className="col-lg-4">
                            {
                                (short_data || []).sort(compareDateTime).slice(0, 2).map((d, x) => {
                                    return (
                                        <div className="ps-post-list-container post-list-tops mb-5" key={`post-slide-single-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-list-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-list-content">
                                                <div>
                                                    <div className="ps-post-list-date mt-3 mb-3">
                                                        {Dates({ data: d.created_date })}
                                                    </div>
                                                    <div className="ps-post-list-title">
                                                        {d.title}
                                                    </div>
                                                    <div className="ps-post-list-icon">
                                                        <div className="me-3">
                                                            <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                        </div>
                                                        <div className="me-3">
                                                            <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                        </div>
                                                        <div className="me-3">
                                                            <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-lg-8">
                            <Swiper
                                ref={sliderRef}
                                loop={true}
                                modules={[Autoplay]}
                                autoplay={{ delay: 2500 }}
                                className="slider-arrows"
                            >
                                {
                                    (slider_data || []).slice(0, 8).sort(compareDateTime).map((d, x) => {
                                        return (
                                            <SwiperSlide key={`post-slide-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                                <div className="ps-post-big-container bg-none">
                                                    <div className="ps-post-big-image h-410px" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                                    <div className="ps-post-big-content text-center border-none bg-none">
                                                        <div className="ps-post-list-date">
                                                            {Dates({ data: d.created_date })}
                                                        </div>
                                                        <div className="ps-post-big-title text-start">
                                                            {d.title}
                                                        </div>
                                                        <div className="ps-post-big-text text-start">
                                                            {d.description}
                                                        </div>
                                                        <div className="ps-post-big-icon">
                                                            <div className="me-3">
                                                                <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                            </div>
                                                            <div className="me-3">
                                                                <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                            </div>
                                                            <div className="me-3">
                                                                <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                                <div className="prev-arrow" onClick={handlePrev} >
                                    <i className="fa-solid fa-angle-left text-white"></i>
                                </div>
                                <div className="next-arrow" onClick={handleNext} >
                                    <i className="fa-solid fa-angle-right text-white"></i>
                                </div>
                            </Swiper>
                        </div>
                    </div>
                </section>

                { /* Mobile */}
                <div className="ps-bg-gray"></div>
                <section className="d-lg-none d-sm-block mt-3" style={{ position: "relative", zIndex: 50 }}>
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                (SwitzerlandData || []).sort(compareDateTime).slice(0, 1).map((d, x) => {
                                    return (
                                        <div className="ps-post-big-container bg-white" key={`post-culture-big-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-big-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                            </div>
                                            <div className="ps-post-big-content border-none bg-none">
                                                <div className="ps-post-big-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-big-text">
                                                    {d.description}
                                                </div>
                                                <div className="ps-post-big-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-lg-12">
                            {
                                (SwitzerlandData || []).sort(compareDateTime).slice(1, 10).map((d, x) => {
                                    return (
                                        <div className="ps-post-list-container mb-1" key={`post-slide-single-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-list-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-list-content">
                                                <div>
                                                    <div className="ps-post-list-date mt-3 mb-3">
                                                        {Dates({ data: d.created_date })}
                                                    </div>
                                                    <div className="ps-post-list-title">
                                                        {d.title}
                                                    </div>
                                                    <div className="ps-post-list-icon">
                                                        <div className="me-3">
                                                            <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                        </div>
                                                        <div className="me-3">
                                                            <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                        </div>
                                                        <div className="me-3">
                                                            <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </>
        )
    }
    const Section_special = ({ section_data }) => {

        return (
            <>
                { /* Desktop */}
                <section className="d-lg-block d-sm-none">
                    <Section_head title={"Özel Haberler"} url={"/ozel-haber/2"} />
                    <div className="row">
                        <div className="col-lg-8">
                            {
                                (section_data || []).sort(compareDateTime).slice(2, 3).map((d, x) => {
                                    return (
                                        <div className="ps-post-big-container bg-none" key={`post-special-big-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-big-image h-410px" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-big-content border-none bg-none">
                                                <div className="ps-post-big-date">
                                                    {Dates({ data: d.created_date })}
                                                </div>
                                                <div className="ps-post-big-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-big-text">
                                                    {d.description}
                                                </div>
                                                <div className="ps-post-big-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-lg-4">
                            {
                                (section_data || []).sort(compareDateTime).slice(3, 10).map((d, x) => {
                                    return (
                                        <div className="ps-post-list-container post-list-special" key={`post-special-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-list-image d-lg-none" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-list-content mt-0">
                                                <div className="ps-post-list-date">
                                                    {Dates({ data: d.created_date })}
                                                </div>
                                                <div className="ps-post-list-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-list-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>

                { /* Mobile */}
                <section className="d-lg-none d-sm-block mb-3">
                    <Section_head title={"Özel Haberler"} url={"/ozel-haber/2"} />
                    <div className="row">
                        <div className="col-lg-8">
                            {
                                (section_data || []).sort(compareDateTime).slice(0, 1).map((d, x) => {
                                    return (
                                        <div className="ps-post-big-container bg-none" key={`post-special-big-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-big-image h-410px" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-big-content border-none bg-none">
                                                <div className="ps-post-big-date">
                                                    {Dates({ data: d.created_date })}
                                                </div>
                                                <div className="ps-post-big-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-big-text">
                                                    {d.description}
                                                </div>
                                                <div className="ps-post-big-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-lg-4">
                            {
                                (section_data || []).sort(compareDateTime).slice(1, 10).map((d, x) => {
                                    return (
                                        <div className="ps-post-list-container post-list-special" key={`post-special-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-list-image d-lg-none" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-list-content mt-0">
                                                <div className="ps-post-list-date">
                                                    {Dates({ data: d.created_date })}
                                                </div>
                                                <div className="ps-post-list-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-list-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </>
        )

    }
    const Section_agenda = ({ section_data }) => {

        return (
            <>
                { /* Desktop */}
                <section className="d-lg-block d-sm-none">
                    <Section_head title={"Gündem"} url={"/gundem/3"} />
                    <div className="row">
                        {
                            (section_data || []).sort(compareDateTime).slice(0, 6).map((d, x) => {
                                return (
                                    <div className="col-lg-4 col-12" key={`post-agenda-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                        <div className={`ps-post-list-container`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-list-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-list-content">
                                                <div className="ps-post-list-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-list-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>

                { /* Mobile */}
                <section className="d-lg-none d-sm-block mb-3">
                    <Section_head title={"Gündem"} url={"/gundem/3"} />
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                (section_data || []).sort(compareDateTime).slice(0, 1).map((d, x) => {
                                    return (
                                        <div className="ps-post-big-container bg-none" key={`post-culture-big-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-big-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                            </div>
                                            <div className="ps-post-big-content border-none bg-none">
                                                <div className="ps-post-big-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-big-text">
                                                    {d.description}
                                                </div>
                                                <div className="ps-post-big-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            (section_data || []).sort(compareDateTime).slice(1, 10).map((d, x) => {
                                return (
                                    <div className="col-lg-4 col-12" key={`post-agenda-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                        <div className={`ps-post-list-container`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-list-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-list-content">
                                                <div className="ps-post-list-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-list-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
            </>
        )
    }
    const Section_culture = ({ section_data }) => {

        return (
            <>
                { /* Desktop */}
                <section className="d-lg-block d-sm-none">
                    <Section_head title={"Kültür Sanat"} url={"/kultur-sanat/5"} />
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                (section_data || []).sort(compareDateTime).slice(0, 1).map((d, x) => {
                                    return (
                                        <div className="ps-post-big-container bg-none" key={`post-culture-big-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-big-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                            </div>
                                            <div className="ps-post-big-content border-none bg-none">
                                                <div className="ps-post-big-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-big-text">
                                                    {d.description}
                                                </div>
                                                <div className="ps-post-big-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            (section_data || []).sort(compareDateTime).slice(1, 10).map((d, x) => {
                                return (
                                    <div className="col-lg-3 post-list-culture" key={`post-culture-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                        <div className="ps-post-list-container" onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-list-image shadow-sm" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                            </div>
                                            <div className="ps-post-list-content">
                                                <div className="ps-post-list-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-list-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>

                { /* Mobile */}
                <section className="d-lg-none d-sm-block mb-3">
                    <Section_head title={"Kültür Sanat"} url={"/kultur-sanat/5"} />
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                (section_data || []).sort(compareDateTime).slice(0, 1).map((d, x) => {
                                    return (
                                        <div className="ps-post-big-container bg-none" key={`post-culture-big-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-big-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                            </div>
                                            <div className="ps-post-big-content border-none bg-none">
                                                <div className="ps-post-big-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-big-text">
                                                    {d.description}
                                                </div>
                                                <div className="ps-post-big-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            (section_data || []).sort(compareDateTime).slice(1, 10).map((d, x) => {
                                return (
                                    <div className="col-lg-4 col-12" key={`post-agenda-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                        <div className={`ps-post-list-container`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-list-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-list-content">
                                                <div className="ps-post-list-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-list-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
            </>
        )
    }
    const Section_video = ({ section_data }) => {


        return (
            <>

                { /* Desktop */}
                <section className="d-lg-block d-sm-none">
                    <Section_head title={"Video Haber"} url={"/video-haber/12"} />
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                (section_data || []).sort(compareDateTime).slice(0, 1).map((d, x) => {
                                    return (
                                        <div className="ps-post-big-container bg-none" key={`post-video-big-${d.id}-big-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-big-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                                <div className="icon"><i className="fa-brands fa-youtube"></i></div>
                                            </div>
                                            <div className="ps-post-big-content border-none bg-none">
                                                <div className="ps-post-big-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-big-text">
                                                    {d.description}
                                                </div>
                                                <div className="ps-post-big-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            (section_data || []).slice(1, 10).map((d, x) => {
                                return (
                                    <div className="col-lg-6 post-list-video" key={`post-video-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                        <div className="ps-post-list-container">
                                            <div className="ps-post-list-image h-300px" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                                <div className="icon"><i className="fa-brands fa-youtube"></i></div>
                                            </div>
                                            <div className="ps-post-list-content">
                                                <div className="ps-post-list-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-list-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>

                { /* Mobile */}
                <section className="d-lg-none d-sm-block mb-3">
                    <Section_head title={"Video Haber"} url={"/video-haber/12"} />
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                (section_data || []).sort(compareDateTime).slice(0, 1).map((d, x) => {
                                    return (
                                        <div className="ps-post-big-container bg-none" key={`post-culture-big-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-big-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                            </div>
                                            <div className="ps-post-big-content border-none bg-none">
                                                <div className="ps-post-big-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-big-text">
                                                    {d.description}
                                                </div>
                                                <div className="ps-post-big-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            (section_data || []).sort(compareDateTime).slice(1, 10).map((d, x) => {
                                return (
                                    <div className="col-lg-4 col-12" key={`post-agenda-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                        <div className={`ps-post-list-container`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-list-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-list-content">
                                                <div className="ps-post-list-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-list-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
            </>
        )
    }
    const Section_activity = ({ section_data }) => {

        return (
            <>
                { /* Desktop */}
                <section className="d-lg-block d-sm-none">
                    <Section_head title={"Etkinlik Takvimi"} url={"/etkinlik-takvimi/6"} />
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                (section_data || []).sort(compareDateTime).slice(0, 1).map((d, x) => {
                                    return (
                                        <div className="ps-post-big-container bg-none" key={`post-activity-big-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-big-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                            </div>
                                            <div className="ps-post-big-content border-none bg-none">
                                                <div className="ps-post-big-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-big-text">
                                                    {d.description}
                                                </div>
                                                <div className="ps-post-big-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            (section_data || []).slice(1, 10).map((d, x) => {
                                return (
                                    <div className="col-lg-6 post-list-activity" key={`post-activity-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                        <div className="ps-post-list-container">
                                            <div className="ps-post-list-image h-300px" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                            </div>
                                            <div className="ps-post-list-content">
                                                <div className="ps-post-list-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-list-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>

                { /* Mobile */}
                <section className="d-lg-none d-sm-block mb-3">
                    <Section_head title={"Etkinlik Takvimi"} url={"/etkinlik-takvimi/6"} />
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                (section_data || []).sort(compareDateTime).slice(0, 1).map((d, x) => {
                                    return (
                                        <div className="ps-post-big-container bg-none" key={`post-culture-big-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-big-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                            </div>
                                            <div className="ps-post-big-content border-none bg-none">
                                                <div className="ps-post-big-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-big-text">
                                                    {d.description}
                                                </div>
                                                <div className="ps-post-big-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            (section_data || []).sort(compareDateTime).slice(1, 10).map((d, x) => {
                                return (
                                    <div className="col-lg-4 col-12" key={`post-agenda-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                        <div className={`ps-post-list-container`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-list-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-list-content">
                                                <div className="ps-post-list-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-list-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
            </>
        )
    }
    const Section_interview = ({ section_data }) => {

        return (
            <>

                { /* Mobile */}
                <section className="d-lg-none d-sm-block mb-3">
                    <Section_head title={"Söyleşi"} url={"/soyleyisi/4"} />
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                (section_data || []).sort(compareDateTime).slice(0, 1).map((d, x) => {
                                    return (
                                        <div className="ps-post-big-container bg-none" key={`post-culture-big-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-big-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                            </div>
                                            <div className="ps-post-big-content border-none bg-none">
                                                <div className="ps-post-big-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-big-text">
                                                    {d.description}
                                                </div>
                                                <div className="ps-post-big-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            (section_data || []).sort(compareDateTime).slice(1, 10).map((d, x) => {
                                return (
                                    <div className="col-lg-4 col-12" key={`post-agenda-${d.id}-list-${x}`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                        <div className={`ps-post-list-container`} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="ps-post-list-image" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}></div>
                                            <div className="ps-post-list-content">
                                                <div className="ps-post-list-title">
                                                    {d.title}
                                                </div>
                                                <div className="ps-post-list-icon">
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-regular fa-message me-1 fs-14"></i>0
                                                    </div>
                                                    <div className="me-3">
                                                        <i className="fa-solid fa-share-nodes me-1 fs-14"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
            </>
        )
    }

    return (
        <>


            {/*section_slider & For Mobile Switzelland */}
            <Section_slider slider_data={SlideData} short_data={SpeacialData} />
            <Section_banner section_type={"content_1"} />


            {/*section_special -> Özel Haberler */}
            <Section_special section_data={SpeacialData} />
            <Section_banner section_type={"content_2"} />

            {/*section_agenda -> Gündem Haberler */}
            <Section_agenda section_data={AgendaData} />
            <Section_banner section_type={"content_3"} />

            {/*section_culture -> Kültür Haberler */}
            <Section_culture section_data={CultureData} />
            <Section_banner section_type={"content_4"} />

            {/*section_video -> Video Haberler */}
            <Section_video section_data={VideoData} />
            <Section_banner section_type={"content_5"} />

            {/*section_activity -> Etkinlik Haberler */}
            <Section_activity section_data={ActivityData} />
            <Section_banner section_type={"content_6"} />

            {/*Section_interview -> Söyleşi */}
            <Section_interview section_data={InterviewData} />
        </>
    )
}

export default Index;