import { Link, useLocation, useNavigate } from "react-router-dom"
import { Dates, Declare, Root, Urlgenerator } from "./element"
import { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import axios from "axios";

export function Header({ categories, adsense, topsdata, currency }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [Active, setActive] = useState(false);
    const [Show, setShow] = useState(false);
    useEffect(() => {
        const handleScroll = () => { setActive(window.scrollY > 50); };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);
    const Adsense = ({ page, position }) => {
        const result = (adsense || []).filter((f) => { return (f.page === page && f.position === position) })
        if (result.length > 0) {
            return JSON.parse(result[0].media)
        }
        else {
            return [];
        }
    }
    const [SearchValue, setSearchValue] = useState("")
    const [suggestions, setSuggestions] = useState([]);
    useEffect(() => {
        const debounce = setTimeout(() => {
            if (SearchValue) {
                axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post&limit=20&page=1&conditions=title LIKE '%${encodeURIComponent(SearchValue)}%'`).then((data) => {
                    setSuggestions(data.data.data);
                })
            }
            else {
                setSuggestions([]);
            }
        }, 300);
        return () => clearTimeout(debounce);
    }, [SearchValue]);
    const Routes = (category, title, id) => {
        if (category !== undefined) {
            const result = categories.filter((f) => { return (f.id === category) })
            navigate(`${Urlgenerator({ data: result[0]["category"] })}/${Urlgenerator({ data: title })}/${id}`)
        }
    }
    useEffect(() => {
        setShow(false)
        setDrawer("")
    }, [location.pathname])

    const CurrencyDeclare = (id) => {
        if (currency !== undefined) {
            if (currency.length > 0) {
                return (
                    <>
                        {currency[id]["Isim"]} <i className="fa-solid fa-circle-dot me-2 ms-2"></i>
                        ALIŞ: {currency[id]["BanknoteBuying"]}  <i className="fa-solid fa-arrow-right-arrow-left me-2 ms-2"></i>
                        SATIŞ: {currency[id]["BanknoteSelling"]}
                    </>
                )
            }
            else {
                return <></>
            }
        }
        else {
            return <></>
        }

    }


    const NewsSlide = () => {
        return (
            <Swiper loop={true} modules={[Autoplay]} autoplay={{ delay: 4500 }} direction={'vertical'} style={{ height: "50px" }}>
                {
                    (topsdata || []).map((d, x) => {
                        return (
                            <SwiperSlide key={`tops-list-${d["id"]}-list-${x}`} style={{ height: "50px" }}>
                                <div className="ps-top-item cursor-pointer" onClick={() => { Routes(d.category, d.title, d.post_id) }} style={{ height: "50px" }}>
                                    {d["title"]}
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        )
    }
    const CurrenySlide = () => {
        return (
            <Swiper loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }} direction={'vertical'} style={{ height: "50px", marginLeft: 0 }}>
                <SwiperSlide style={{ height: "50px" }}>
                    <div className="ps-top-item" style={{ height: "50px", fontSize: "14px" }}>
                        {CurrencyDeclare(5)}
                    </div>
                </SwiperSlide>
                <SwiperSlide style={{ height: "50px" }}>
                    <div className="ps-top-item" style={{ height: "50px", fontSize: "14px" }}>
                        {CurrencyDeclare(0)}
                    </div>
                </SwiperSlide>
                <SwiperSlide style={{ height: "50px" }}>
                    <div className="ps-top-item" style={{ height: "50px", fontSize: "14px" }}>
                        {CurrencyDeclare(3)}
                    </div>
                </SwiperSlide>
            </Swiper>
        )
    }
    const [Drawer, setDrawer] = useState("")
    return (
        <>
            <div className="device-desktop">
                <div className="ps-top">
                    <div className="container">
                        <div className="d-flex">
                            <div className="w-100 d-flex justify-content-start border-r">
                                <CurrenySlide />
                            </div>
                            <div className="w-400px d-flex justify-content-end align-items-center bg-white" style={{ height: "50px", borderBottom: "solid 1px #ccc" }}>
                                <div className="ps-header-icon cursor-pointer  text-dark" onClick={() => { setShow(true) }}>
                                    <i className="fa-solid fa-magnifying-glass fs-5 me-2 text-dark"></i>
                                    <div className="text-dark">Pusula Arama...</div>
                                </div>
                            </div>
                            {
                                /*
                                <div className="w-400px d-flex justify-content-end align-items-center" >
                                <div className="ps-login" onClick={() => { navigate(`/abone/hesap-olustur`) }}>
                                    <i className="fa-regular fa-circle-user me-2 ms-1"></i>
                                    Abone Ol
                                </div>
                                <div className="ps-login" onClick={() => { navigate(`/abone/giris-yap`) }}>
                                    <i className="fa-solid fa-right-to-bracket me-2 ms-1"></i>
                                    Giriş Yap
                                </div>
                            </div>
                                */
                            }
                        </div>
                    </div>
                </div>
                <div className="container mb-1 mt-1">
                    <div>
                        <div className="row">
                            <div className="col-3">
                                <Link to={"/"} className="header-top-logo" style={{ marginLeft: "14px" }}>
                                    <img src="https://ik.imagekit.io/tey21emoa/Other/pusula-swiss-logo.svg?updatedAt=1716235135624" height={70} width={176} alt="Pusula Swiss Logo" />
                                </Link>
                            </div>
                            <div className="col-9">
                                <Swiper loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }} spaceBetween={35} className="slider-arrows pr-swiper">
                                    {
                                        Adsense({ page: "header", position: "content_header" }).map((d, x) => {
                                            return (
                                                <SwiperSlide key={`banner-${d.position}-list-${x}`}>
                                                    <div className="ps-post-banner mb-0 border-0">
                                                        <a href={`${d.url}`} target="_blank" >
                                                            <img src={`${Root({ data: { type: "banner" } })}${d.blob}`} className="border" width={"964px"} height={"118px"} alt="pusula-swiss-banner" />
                                                        </a>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`ps-header shadow-sm ${Active ? "sticky" : ""}`}>
                    <div className="container">
                        <div className="ps-header-container">
                            <div className={`ps-header-logo ${Active ? "" : "disabled"}`} style={{ marginLeft: "12px" }}>
                                <Link to={"/"}>
                                    <img src="https://ik.imagekit.io/tey21emoa/Other/pusula-swiss-logo.svg?updatedAt=1716235135624" height={50} width={126} alt="Pusula Swiss Logo" />
                                </Link>
                            </div>
                            <div className={`ps-header-menu ${Active ? "justify-content-end" : ""}`}>
                                {(categories || []).map((d, x) => { return (<div hidden={d.id === 8 ? true : false} style={{ marginLeft: "10px" }} key={`header-menu-item-${d["id"]}`} className={`item`}><Link className={`${location.pathname === `/${Urlgenerator({ data: d["category"] })}/${d["id"]}` ? "active" : ""}`} to={`${Urlgenerator({ data: d["category"] })}/${d["id"]}`}>{d["category"]}</Link></div>) })}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="ps-header-space"></div>
                <div className={`ps-search-modal ${Show ? "ps-search-modal-active" : ""}`}>
                    <div className="container">
                        <div className="ps-search-modal-container" tabIndex={2} >
                            <div className="row">
                                <div className="col-lg-12 d-flex justify-content-end mb-3">
                                    <i className="fa-solid fa-circle-xmark fs-2 cursor-pointer" onClick={() => { setShow(false) }}></i>
                                </div>
                                <div className="col-lg-12 mt-2">
                                    <div className="d-flex mb-2">
                                        <div className="w-100">
                                            Aranan Kelime: <b>{SearchValue === "" ? "Haber, Kategori veya Konu Arayın..." : SearchValue}</b>
                                        </div>
                                        <div className="w-100 d-flex justify-content-end">
                                            Bulunan: <b>{suggestions.length}</b>
                                        </div>
                                    </div>
                                    <div>
                                        <input className="ps-input h-50px" placeholder="Haber, Kategori veya Konu Arayın..." value={SearchValue} onChange={(e) => { setSearchValue(e.target.value) }} />
                                        <div style={{ position: "relative" }}>
                                            {suggestions.length > 0 && (
                                                <div className="ps-dynamic-dialog">
                                                    {suggestions.map((d, x) => (
                                                        <div className="item" key={x} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                                            <div className="w-300px me-3">
                                                                <div className="ps-background-img h-100px rounded-1" style={{ backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>

                                                                </div>
                                                            </div>
                                                            <div className="w-100">{d.title}</div>
                                                            <div className="w-100 d-flex justify-content-end">{Dates({ data: d.created_date })}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ps-search-modal-empty">
                                <div className="text-center">
                                    <div><i className="fa-regular fa-folder-open"></i></div>
                                    <label>Haber, Kategori veya Konu Arayın...</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="device-mobile">
                <div className={`ps-mobile-header ${Active ? "shadow-sm" : ""}  sticky w-100`}>
                    <div className="w-100">
                        <Link to={"/"}>
                            <img src="https://ik.imagekit.io/tey21emoa/Other/pusula-swiss-logo.svg?updatedAt=1716235135624" height={50} width={176} alt="Pusula Swiss Logo" />
                        </Link>
                    </div>
                    <div className="ps-header-icon cursor-pointer" style={{ width: "70px" }} onClick={() => { setDrawer("_search") }}>
                        <i className="fa-solid fa-magnifying-glass fs-5 me-2"></i>
                    </div>
                    <div className="w-50px cursor-pointer" onClick={() => { setDrawer("_menu") }}>
                        <i className="fa-solid fa-bars"></i>
                    </div>
                </div>
                <div className="ps-header-space h-70px"></div>

                {/*Menu */}
                <div className={`ps-mobile-drawer drawer-left w-80 shadow-sm ${Drawer === "_menu" ? "active" : ""}`}>
                    <div className="ps-mobile-drawer-head">
                        <Link to={"/"}>
                            <img src="https://ik.imagekit.io/tey21emoa/Other/pusula-swiss-logo.svg?updatedAt=1716235135624" height={50} width={176} alt="Pusula Swiss Logo" />
                        </Link>
                        <div className="w-100 d-flex justify-content-end align-items-center" onClick={() => { setDrawer("") }}>
                            <i className="fa-solid fa-rectangle-xmark"></i>
                        </div>
                    </div>
                    <div className="ps-mobile-drawer-body">
                        <div className="ps-mobile-drawer-menu">
                            {(categories || []).map((d, x) => {
                                return (
                                    <div key={`header-menu-item-${d["id"]}`} className={`item`} hidden={d.id === 8 ? true : false}>
                                        <Link
                                            className={`${location.pathname === `/${Urlgenerator({ data: d["category"] })}/${d["id"]}` ? "active" : ""}`}
                                            to={`${Urlgenerator({ data: d["category"] })}/${d["id"]}`}>
                                            <div className="icon">
                                                <i className="fa-solid fa-circle-dot" style={{ fontSize: "12px" }}></i>
                                            </div>
                                            <div className="w-100">{d["category"]}</div>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={`ps-mobile-drawer-overlay ${Drawer === "_menu" ? "active" : ""}`} onClick={() => { setDrawer("") }}></div>

                {/*Search */}
                <div className={`ps-mobile-drawer drawer-left w-100 shadow-sm ${Drawer === "_search" ? "active" : ""}`}>
                    <div className="ps-mobile-drawer-head">
                        <Link to={"/"}>
                            <img src="https://ik.imagekit.io/tey21emoa/Other/pusula-swiss-logo.png" alt="Pusula Swiss Logo" />
                        </Link>
                        <div className="w-100 d-flex justify-content-end align-items-center" onClick={() => { setDrawer("") }}>
                            <i className="fa-solid fa-rectangle-xmark"></i>
                        </div>
                    </div>
                    <div className="ps-mobile-drawer-body" style={{ overflow: "hidden" }}>
                        <div className="w-100 d-flex justify-content-end">
                            Bulunan: <b>{suggestions.length}</b>
                        </div>
                        <input className="ps-input h-50px" placeholder="Haber, Kategori veya Konu Arayın..." value={SearchValue} onChange={(e) => { setSearchValue(e.target.value) }} />
                        <div style={{ position: "relative" }}>
                            {suggestions.length > 0 && (
                                <div className="ps-dynamic-dialog p-2" style={{ height: "100vh" }}>
                                    {suggestions.map((d, x) => (
                                        <div className="item mb-2 border" style={{ height: "110px" }} key={x} onClick={() => { Routes(d.category, d.title, d.post_id) }}>
                                            <div className="w-300px me-3" style={{ height: "100px" }}>
                                                <div className="ps-background-img rounded-1" style={{ borderRight: "solid 1px #d7d7d7", height: "100px", backgroundImage: `url(${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })})` }}>
                                                </div>
                                            </div>
                                            <div className="w-100 d-flex align-items-center" style={{ height: "100px" }}>
                                                <div>
                                                    <div className="w-100" style={{ fontSize: "14px", lineHeight: "18px", color: "#2b2b2b", fontWeight: "600" }}>{Dates({ data: d.created_date })}</div>
                                                    <div style={{ fontSize: "14px", lineHeight: "18px", color: "#2b2b2b", fontWeight: "500" }}>{d.title}</div>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="ps-search-modal-empty">
                            <div className="text-center">
                                <div><i className="fa-regular fa-folder-open  text-dark"></i></div>
                                <label className=" text-dark">Haber, Kategori veya Konu Arayın...</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`ps-mobile-drawer-overlay ${Drawer === "_search" ? "active" : ""}`} onClick={() => { setDrawer("") }}></div>
            </div>
        </>
    )
}
export function Footer({ categories, currency }) {
    const navigate = useNavigate()
    const location = useLocation()

    const [Drawer, setDrawer] = useState("")
    useEffect(() => {
        setDrawer("")
    }, [location.pathname])


    return (
        <>
            <div className="device-desktop">
                <div className="ps-footer">
                    <div className="container mt-5">
                        <div className="d-flex">
                            <div className="w-100">
                                <img src="https://ik.imagekit.io/tey21emoa/Other/pusula-swiss-logo.svg?updatedAt=1716235135624" height={70} width={176} alt="Pusula Swiss Logo" />
                            </div>
                            <div className="w-100">
                                <label className="ps-footer-title">Sayfalar</label>
                                <div className="ps-footer-shape"></div>
                                <div className="ps-footer-menu">
                                    <div className="item"><Link to={"/hakkimizda"}>HAKKIMIZDA</Link></div>
                                    <div className="item"><Link to={"/kunye"}>KÜNYE</Link></div>
                                    <div className="item"><Link to={"/iletisim"}>İLETİŞİM</Link></div>
                                    <div className="item"><a target="_blank" href={"https://ik.imagekit.io/tey21emoa/Other/pusula-swiss-ads.pdf"}>REKLAM VE TANITIM</a></div>
                                </div>
                            </div>
                            <div className="w-100">
                                <label className="ps-footer-title">Yasal</label>
                                <div className="ps-footer-shape"></div>
                                <div className="ps-footer-menu">
                                    <div className="item"><Link to="/belgeler/abonelik-hakkinda">ABONELİK HAKKINDA</Link></div>
                                    <div className="item"><Link to="/belgeler/kisisel-verilerin-korunmasi">GİZLİLİK POLİTİKASI</Link></div>
                                    <div className="item"><Link to="/belgeler/hizmet-sartlari">HİZMET ŞARTLARI</Link></div>
                                </div>
                            </div>
                            <div className="w-100">
                                <label className="ps-footer-title">Kategoriler</label>
                                <div className="ps-footer-shape"></div>
                                <div className="ps-footer-menu">
                                    {(categories || []).map((d, x) => { return (<div key={`footer-menu-item-${d["id"]}`} className="item"><Link to={`${Urlgenerator({ data: d["category"] })}/${d["id"]}`}>{d["category"]}</Link></div>) })}
                                </div>
                            </div>
                            <div className="w-100">
                                <label className="ps-footer-title">Sosyal Medya</label>
                                <div className="ps-footer-shape"></div>
                                <div className="ps-footer-menu">
                                    <div className="item"><a target="_blank" href="https://www.facebook.com/PusulaSwiss">FACEBOOK</a></div>
                                    <div className="item"><a target="_blank" href="https://www.instagram.com/pusula.swiss/">INSTAGRAM</a></div>
                                    <div className="item"><a target="_blank" href="https://www.youtube.com/@Pusulaswiss">YOUTUBE</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="device-mobile">
                <div className="ps-mobile-footer shadow-sm">
                    <div className="w-100 text-center" onClick={() => { navigate("/") }}>
                        <div className="footer-icon"><i className="fa-solid fa-house"></i></div>
                        <div className="footer-title">Anasayfa</div>
                    </div>
                    <div className="w-100 text-center" onClick={() => { navigate("/etkinlik-takvimi/6") }}>
                        <div className="footer-icon"><i className="fa-solid fa-award"></i></div>
                        <div className="footer-title">Etkinlikler</div>
                    </div>
                    <div className="w-100 text-center" onClick={() => { navigate("/video-haber/12") }}>
                        <div className="footer-icon"><i className="fa-solid fa-video"></i></div>
                        <div className="footer-title">Video</div>
                    </div>
                </div>
                <div className="ps-header-space h-70px"></div>
                <div className={`ps-mobile-drawer drawer-right w-100 shadow-sm ${Drawer === "_exchange" ? "active" : ""}`}>
                    <div className="ps-mobile-drawer-head">
                        <div className="w-100 text-dark">Döviz Bilgileri</div>
                        <div className="w-100 d-flex justify-content-end" onClick={() => { setDrawer("") }}>
                            <i className="fa-solid fa-rectangle-xmark"></i>
                        </div>
                    </div>

                    <div className="ps-mobile-drawer-body pt-0">
                        <div className="ps-exchenge-container">
                            {
                                currency !== undefined &&
                                currency.length > 0 &&
                                <>
                                    <div className="ps-exchenge-item">
                                        <div className="icon">
                                            <i className="fa-solid fa-franc-sign"></i>
                                        </div>
                                        <div className="content">
                                            <div className="title">
                                                <div className="ps-exchenge-name">FRANG</div>
                                                <div className="d-flex">
                                                    <div className="w-100 ps-exchenge-price">
                                                        <div className="ps-exchenge-info">Alış Fiyatı</div>
                                                        {currency[5].BanknoteBuying}
                                                    </div>
                                                    <div className="w-100 ps-exchenge-price">
                                                        <div className="ps-exchenge-info">Satış Fiyatı</div>
                                                        {currency[5].BanknoteSelling}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ps-exchenge-item">
                                        <div className="icon">
                                            <i className="fa-solid fa-euro-sign"></i>
                                        </div>
                                        <div className="content">
                                            <div className="title">
                                                <div className="ps-exchenge-name">EURO</div>
                                                <div className="d-flex">
                                                    <div className="w-100 ps-exchenge-price">
                                                        <div className="ps-exchenge-info">Alış Fiyatı</div>
                                                        {currency[3].BanknoteBuying}
                                                    </div>
                                                    <div className="w-100 ps-exchenge-price">
                                                        <div className="ps-exchenge-info">Satış Fiyatı</div>
                                                        {currency[3].BanknoteSelling}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ps-exchenge-item">
                                        <div className="icon">
                                            <i className="fa-solid fa-dollar-sign"></i>
                                        </div>
                                        <div className="content">
                                            <div className="title">
                                                <div className="ps-exchenge-name">DOLAR</div>
                                                <div className="d-flex">
                                                    <div className="w-100 ps-exchenge-price">
                                                        <div className="ps-exchenge-info">Alış Fiyatı</div>
                                                        {currency[0].BanknoteBuying}
                                                    </div>
                                                    <div className="w-100 ps-exchenge-price">
                                                        <div className="ps-exchenge-info">Satış Fiyatı</div>
                                                        {currency[0].BanknoteSelling}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className={`ps-mobile-drawer-overlay ${Drawer === "_exchange" ? "active" : ""}`} onClick={() => { setDrawer("") }}></div>
            </div>



        </>
    )
}