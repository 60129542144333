import { Link, useParams } from "react-router-dom"



function SubscriptionTerms() {

    return (
        <>
            <div className="container mb-5 m-0 p-0">
                <div className="ps-document">
                    <b>E-Posta Aboneliği Hakkında Kullanıcı Bilgilendirmesi</b>
                    <p>
                        Kompass Group GmbH olarak, kullanıcılarımıza değerli içerikler ve güncellemeler sunmak amacıyla e-posta abonelik hizmeti sağlamaktayız. E-posta aboneliklerimiz ile ilgili yasal bilgilendirmeleri ve kullanıcı haklarını aşağıda bulabilirsiniz.
                    </p>
                    <b>1. Abonelik ve Onay</b>
                    <p>
                        E-posta aboneliği, kullanıcıların belirli içeriklere, promosyonlara veya güncellemelere düzenli olarak erişim sağlamalarını amaçlar. Aboneliğinizi başlatmak için, kayıt formunda istenilen bilgileri doldurmanız ve abonelik onayınızı vermeniz gerekmektedir. Onay işlemi, ilgili yasal mevzuat gereği, açık rıza beyanınızı içerir.
                    </p>
                    <b>2. Kişisel Bilgilerin Toplanması ve Kullanımı</b>
                    <p>
                        E-posta aboneliği sırasında topladığımız kişisel bilgiler (örneğin, adınız ve e-posta adresiniz), yalnızca belirttiğimiz amaçlar doğrultusunda kullanılacaktır. Bu bilgiler, size en güncel içerikleri, promosyonları ve önemli bildirimleri iletmek için kullanılacaktır.
                    </p>
                    <b>3. Abonelikten Çıkma (Unsubscribe) Hakkı</b>
                    <p>
                        Aboneliğinizi istediğiniz zaman sonlandırma hakkına sahipsiniz. E-posta aboneliğinden çıkmak için her e-postanın alt kısmında bulunan "Abonelikten Çık" veya "Unsubscribe" bağlantısını kullanabilirsiniz. Ayrıca, [Müşteri Hizmetleri E-posta Adresi] adresine e-posta göndererek de aboneliğinizi iptal edebilirsiniz.
                    </p>
                    <b>4. Bilgi Güvenliği</b>
                    <p>
                        Kişisel bilgilerinizin güvenliği bizim için çok önemlidir. E-posta abonelik bilgileriniz, yetkisiz erişim, ifşa, değiştirme veya yok etmeye karşı korunmaktadır. Bu amaçla, uygun teknik ve organizasyonel güvenlik önlemleri uygulanmaktadır.
                    </p>
                    <b>5. Üçüncü Taraflarla Paylaşım</b>
                    <p>
                        E-posta aboneliğiniz sırasında topladığımız kişisel bilgiler, sizin izniniz olmadan üçüncü taraflarla paylaşılmayacaktır. Ancak, hizmet sağlayıcılarımızın bize yardımcı olması için belirli durumlarda bilgilerinizi paylaşmamız gerekebilir. Bu hizmet sağlayıcılar, bilgilerinizi yalnızca bizim adımıza ve belirtilen amaçlar doğrultusunda kullanmakla yükümlüdür.
                    </p>
                    <b>6. Çerezler ve Takip Teknolojileri</b>
                    <p>
                        E-posta aboneliklerimizde, e-posta açılma oranları, tıklama oranları ve diğer etkileşim verilerini toplamak için çerezler ve benzeri takip teknolojileri kullanılabilir. Bu bilgiler, size daha iyi hizmet sunmamıza yardımcı olur.
                    </p>
                    <b>7. Yasal Dayanak</b>
                    <p>
                        E-posta aboneliklerimiz, [Ülkenizin Adı] yasalarına uygun olarak yürütülmektedir. Kişisel verilerin korunması ve gizliliği konusundaki yasal düzenlemelere tam uyum sağlanmaktadır.
                    </p>
                    <b>8. Değişiklikler ve Güncellemeler</b>
                    <p>
                        Bu e-posta abonelik bilgilendirmesi, zaman zaman güncellenebilir. Değişiklikler yapıldığında, bu sayfada güncellenen politikalar yayınlanacaktır. Abonelerimize yapılan önemli değişiklikler hakkında bilgi vereceğiz.
                    </p>
                    <b>9. İletişim</b>
                    <p>
                        E-posta aboneliklerimizle ilgili herhangi bir sorunuz veya endişeniz varsa, lütfen bizimle iletişime geçin: info@pusulaswiss.ch
                    </p>
                </div>
            </div>
        </>
    )
}

function ProtectionOfPersonalData() {

    return (
        <>
            <div className="container mb-5">
                <div className="ps-document">
                    <b>Giriş</b>
                    <p>
                        Pusulaswiss.ch, kullanıcılarının gizliliğine büyük önem vermektedir. Bu Gizlilik Politikası, Pusulaswiss.ch'in hangi kullanıcı verilerini topladığı,
                        bu verilerin nasıl kullanıldığı ve korunduğu ile ilgili bilgileri içermektedir.
                    </p>

                    <b> Veri Toplama</b>
                    <p>
                        Web sitemizi ziyaret ettiğinizde, IP adresiniz, ziyaret ettiğiniz sayfalar, ziyaretin tarihi ve saati gibi belirli bilgiler otomatik olarak toplanır.
                        Ayrıca, haber bültenimize abone olursanız e-posta adresinizi de toplarız.
                    </p>

                    <b>Veri Kullanımı</b>
                    <p>
                        Topladığımız bilgiler, web sitemizin kullanımını analiz etmek, hizmetlerimizi geliştirmek ve kullanıcı deneyimini iyileştirmek amacıyla kullanılır. E-posta adresiniz, size haber bültenlerini göndermek için kullanılır.
                    </p>

                    <b>Veri Paylaşımı</b>
                    <p>
                        Toplanan veriler, yasal zorunluluklar dışında üçüncü taraflarla paylaşılmaz. Yasal zorunluluklar kapsamında veri paylaşımı gerektiğinde,
                        kullanıcılarımızın mahremiyetini korumak için gereken tüm önlemler alınır.
                    </p>

                    <b>Çerezler</b>
                    <p>
                        Web sitemiz, kullanıcı deneyimini iyileştirmek için çerezler kullanabilir. Kullanıcılar, tarayıcı ayarları üzerinden çerezleri kontrol edebilir ve gerektiğinde engelleyebilir.
                    </p>

                    <b>Gizlilik Politikası Değişiklikleri</b>
                    <p>
                        Pusulaswiss.ch, gizlilik politikasında değişiklik yapma hakkını saklı tutar. Politikada yapılan değişiklikler, web sitemizde yayınlanacaktır.
                    </p>


                    <b>İletişim</b>
                    <p>
                        Gizlilik politikamızla ilgili sorularınız için [e-posta adresiniz] üzerinden bizimle iletişime geçebilirsiniz.
                    </p>

                    <b>Şartlar & Koşullar Taslağı</b>
                    <p>
                        Giriş Pusulaswiss.ch web sitesini kullanarak, bu Şartlar ve Koşulları kabul etmiş olursunuz. Lütfen bu belgeyi dikkatlice okuyun.
                    </p>

                    <b>Hizmet Kullanımı</b>
                    <p>
                        Web sitemiz, İsviçre'den haberler sunar ve Türkçe dilinde hizmet verir. İçeriklerimiz kişisel ve ticari olmayan kullanım içindir.
                    </p>

                    <b>Fikri Mülkiyet</b>
                    <p>
                        Web sitemizde yer alan tüm içerikler (metinler, görseller, logolar vb.) Pusulaswiss.ch'in mülkiyetindedir ve telif hakkı yasaları ile korunmaktadır.
                    </p>

                    <b>Sorumluluk Reddi</b>
                    <p>
                        Pusulaswiss.ch, web sitemizde yer alan bilgilerin doğruluğu ve güncelliği konusunda hiçbir garanti vermez. Kullanıcılar, bu bilgilere dayanarak hareket etmeden önce kendi bağımsız doğrulamalarını yapmalıdır.
                    </p>

                    <b>Değişiklikler</b>
                    <p>
                        Web sitemizdeki içerik ve Şartlar & Koşullar, önceden haber verilmeksizin değiştirilebilir. Değişiklikler web sitemizde yayınlandıktan sonra geçerli olur.
                    </p>

                    <b>Yasal Uyum</b>
                    <p>
                        Kullanıcılar, web sitemizi kullanırken tüm yerel, ulusal ve uluslararası yasalara uymakla yükümlüdür.
                    </p>

                    <b>İletişim</b>
                    <p>
                        Şartlar ve Koşullarımızla ilgili sorularınız için [e-posta adresiniz] üzerinden bizimle iletişime geçebilirsiniz.
                    </p>
                </div>
            </div>
        </>
    )
}

function TermsOfService() {

    return (
        <>
            <div className="container mb-5">
                <div className="ps-document">
                    <b>Hizmet Şartları</b>
                    <p>
                        Bu hizmet şartları ("Şartlar"), www.pusulaswiss.ch web sitesinin (www.pusulaswiss.ch) kullanımını düzenler. Bu web sitesine erişerek veya bu web sitesini kullanarak, bu Şartları kabul etmiş sayılırsınız. Bu Şartları kabul etmiyorsanız, lütfen web sitemizi kullanmayın.
                    </p>

                    <b>
                        1. Kullanım Koşulları
                    </b>
                    <p>
                        www.pusulaswiss.ch web sitesini kullanırken aşağıdaki koşullara uymalısınız:
                    </p>
                    <p>
                        Web sitesini yalnızca yasal amaçlarla ve bu Şartlara uygun olarak kullanacaksınız.
                        Web sitesine veya içeriğine zarar verecek, kesintiye uğratacak veya aşırı yük bindirecek herhangi bir işlem yapmayacaksınız.
                        Diğer kullanıcıların web sitesini kullanmasını engelleyecek veya kısıtlayacak herhangi bir davranışta bulunmayacaksınız.
                    </p>
                    <b>
                        2. İçerik Kullanımı
                    </b>
                    <p>
                        www.pusulaswiss.ch web sitesinde bulunan tüm içerikler, metinler, grafikler, logolar, resimler, ses dosyaları, videolar ve yazılımlar dahil ancak bunlarla sınırlı olmamak üzere, [Şirket Adı] veya içerik sağlayıcılarının mülkiyetindedir ve telif hakkı yasalarıyla korunmaktadır. İçeriği, [Şirket Adı]'nın önceden yazılı izni olmaksızın, ticari olmayan kişisel kullanım dışında kopyalayamaz, çoğaltamaz, dağıtamaz veya kamuya açık olarak sergileyemezsiniz.
                    </p>
                    <b>
                        3. Kullanıcı İçeriği
                    </b>
                    <p>
                        Web sitesine kullanıcı tarafından yüklenen veya gönderilen içerikler (yorumlar, makaleler, videolar, vb.) aşağıdaki şartlara tabidir:
                    </p>
                    <p>
                        Kullanıcı içeriği yasa dışı, müstehcen, iftira niteliğinde, tehdit edici, gizlilik ihlali teşkil eden veya herhangi bir üçüncü şahsın fikri mülkiyet haklarını ihlal edici nitelikte olmamalıdır.
                        Kompass Group GmbH, kullanıcı içeriğini inceleme, düzenleme veya kaldırma hakkını saklı tutar, ancak bunu yapmak zorunda değildir.
                        Kullanıcı, web sitesine yüklediği tüm içerik için gerekli lisanslara, haklara ve izinlere sahip olduğunu garanti eder.
                    </p>
                    <b>
                        4. Üçüncü Taraf Bağlantıları
                    </b>
                    <p>
                        Web sitemiz, üçüncü taraf web sitelerine bağlantılar içerebilir. Kompass Group GmbH, bu üçüncü taraf sitelerin içeriğinden veya gizlilik uygulamalarından sorumlu değildir. Bu siteleri ziyaret ederken, kendi gizlilik politikalarını ve hizmet şartlarını incelemenizi öneririz.
                    </p>
                    <b>
                        5. Gizlilik
                    </b>
                    <p>
                        Kompass Group GmbH, kullanıcıların gizliliğine önem verir. Kişisel verilerinizin toplanması ve kullanılması ile ilgili bilgiler için lütfen <Link to={"/belgeler/kisisel-verilerin-korunmasi"}>Gizlilik Politikası</Link> adresinde bulunan gizlilik politikamızı inceleyin.
                    </p>
                    <b>
                        6. Sorumluluğun Sınırlandırılması
                    </b>
                    <p>
                        Kompass Group GmbH, web sitesinin kesintisiz veya hatasız olacağını garanti etmez. Web sitesinin kullanımı, kullanıcıların kendi sorumluluğundadır. Kompass Group GmbH, doğrudan, dolaylı, tesadüfi, özel veya sonuç olarak ortaya çıkan zararlar dahil olmak üzere, web sitesinin kullanımından kaynaklanan hiçbir zarardan sorumlu değildir.
                    </p>
                    <b>
                        7. Değişiklikler
                    </b>
                    <p>
                        Kompass Group GmbH, bu Şartları herhangi bir zamanda güncelleme veya değiştirme hakkını saklı tutar. Şartlarda yapılan değişiklikler, web sitesinde yayınlandığı anda yürürlüğe girer. Kullanıcıların bu Şartları düzenli olarak kontrol etmeleri önerilir.
                    </p>
                    <b>
                        8. İletişim
                    </b>
                    <p>
                        Bu Şartlar ile ilgili herhangi bir sorunuz veya endişeniz varsa, lütfen bizimle iletişime geçin: info@pusulaswiss.ch
                    </p>


                </div>
            </div>
        </>
    )
}



function Sitemap() {

    return (
        <>
            <div className="border h-100px d-flex justify-content-center align-items-center">
                <div>
                    <div>Google Search Console Tarafından Oluşturulması bekleniyor</div>
                </div>
            </div>
        </>
    )
}



export default function Documentation() {
    const { rules } = useParams()
    switch (rules) {
        case "abonelik-hakkinda": return <SubscriptionTerms />
        case "kisisel-verilerin-korunmasi": return <ProtectionOfPersonalData />
        case "hizmet-sartlari": return <TermsOfService />
        case "site-haritasi": return <Sitemap />
        default: break;
    }
}