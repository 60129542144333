import { useState } from "react"

function Tags() {
    const [Active, setActive] = useState(1)

    return (
        <>
            <section>
                <div className="container p-0 mb-5">
                    <section className="about-area pt-80 pb-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-12 mt-4">
                                    <div className="tags-item-container">
                                        <div className={`tags-item ${Active === 1 ? "active" : ""}`} onClick={() => { setActive(1) }}>
                                            <i className="fa-solid fa-tag fs-4 me-2"></i>
                                            VERLEGERADRESSE
                                        </div>
                                        <div className={`tags-item ${Active === 2 ? "active" : ""}`} onClick={() => { setActive(2) }}>
                                            <i className="fa-solid fa-tag fs-4 me-2"></i>
                                            CHEFREDAKTOR
                                        </div>
                                        <div className={`tags-item ${Active === 3 ? "active" : ""}`} onClick={() => { setActive(3) }}>
                                            <i className="fa-solid fa-tag fs-4 me-2"></i>
                                            FOTOGRAF & VİDEO
                                        </div>
                                        <div className={`tags-item ${Active === 4 ? "active" : ""}`} onClick={() => { setActive(4) }}>
                                            <i className="fa-solid fa-tag fs-4 me-2"></i>
                                            REDAKTION
                                        </div>
                                        <div className={`tags-item ${Active === 5 ? "active" : ""}`} onClick={() => { setActive(5) }}>
                                            <i className="fa-solid fa-tag fs-4 me-2"></i>
                                            VERTRETER
                                        </div>
                                        <div className={`tags-item ${Active === 6 ? "active" : ""}`} onClick={() => { setActive(6) }}>
                                            <i className="fa-solid fa-tag fs-4 me-2"></i>
                                            FREIE JOURNALISTEN
                                        </div>
                                        <div className={`tags-item ${Active === 7 ? "active" : ""}`} onClick={() => { setActive(7) }}>
                                            <i className="fa-solid fa-tag fs-4 me-2"></i>
                                            INSERATE-ANNAHME
                                        </div>
                                        <div className={`tags-item ${Active === 8 ? "active" : ""}`} onClick={() => { setActive(8) }}>
                                            <i className="fa-solid fa-tag fs-4 me-2"></i>
                                            GRAFIK – LAYOUT
                                        </div>
                                        <div className={`tags-item ${Active === 9 ? "active" : ""}`} onClick={() => { setActive(9) }}>
                                            <i className="fa-solid fa-tag fs-4 me-2"></i>
                                            AUTOREN
                                        </div>
                                        <div className={`tags-item ${Active === 10 ? "active" : ""}`} onClick={() => { setActive(10) }}>
                                            <i className="fa-solid fa-tag fs-4 me-2"></i>
                                            REDAKTIONSLEITERIN
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-12 mt-4">

                                    <div className="card shadow-sm" style={{ borderRadius: "8px", display: `${Active === 1 ? "block" : "none"}` }}>
                                        <div className="card-body">
                                            <h4 className="tag-title">
                                                <i className="fa-solid fa-tag fs-4 me-2"></i>
                                                <span className="ms-1">VERLEGERADRESSE</span>
                                            </h4>
                                            <div className="tag-content">
                                                <p className="mb-0">
                                                    Pusula Media GmbH, Heimstrasse 10,8953 Dietikon
                                                </p>
                                                <p className="mb-0">

                                                </p>
                                                <p className="mb-0">

                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card shadow-sm" style={{ borderRadius: "8px", display: `${Active === 2 ? "block" : "none"}` }}>
                                        <div className="card-body">
                                            <h4 className="tag-title">
                                                <i className="fa-solid fa-tag fs-4 me-2"></i>
                                                <span className="ms-1">CHEFREDAKTOR</span>
                                            </h4>
                                            <div className="tag-content">
                                                <p className="mb-0">
                                                    Turgut Karaboyun
                                                </p>
                                                <p className="mb-0">
                                                    E-Mail: tk@pusulamedia.ch
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card shadow-sm" style={{ borderRadius: "8px", display: `${Active === 3 ? "block" : "none"}` }}>
                                        <div className="card-body">
                                            <h4 className="tag-title">
                                                <i className="fa-solid fa-tag fs-4 me-2"></i>
                                                <span className="ms-1">FOTOGRAF & VİDEO</span>
                                            </h4>
                                            <div className="tag-content">
                                                <p className="mb-0">
                                                    Tamer Karaoglu
                                                </p>
                                                <p className="mb-0">
                                                    Nesrin Buzduğu
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card shadow-sm" style={{ borderRadius: "8px", display: `${Active === 4 ? "block" : "none"}` }}>
                                        <div className="card-body">
                                            <h4 className="tag-title">
                                                <i className="fa-solid fa-tag fs-4 me-2"></i>
                                                <span className="ms-1">REDAKTION</span>
                                            </h4>
                                            <div className="tag-content">
                                                <p className="mb-0">
                                                    044 576 60 80
                                                </p>
                                                <p className="mb-0">
                                                    E-Mail: info@pusulamedia.ch
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card shadow-sm" style={{ borderRadius: "8px", display: `${Active === 5 ? "block" : "none"}` }}>
                                        <div className="card-body">
                                            <h4 className="tag-title">
                                                <i className="fa-solid fa-tag fs-4 me-2"></i>
                                                <span className="ms-1">VERTRETER</span>
                                            </h4>
                                            <div className="tag-content">
                                                <p className="mb-0">
                                                    Kültür & Sanat: Zafer Altun
                                                </p>
                                                <p className="mb-0">
                                                    Istanbul: Yusuf Engin Gezer
                                                </p>
                                                <p className="mb-0">
                                                    İzmir: Mehmet Kuzu
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card shadow-sm" style={{ borderRadius: "8px", display: `${Active === 6 ? "block" : "none"}` }}>
                                        <div className="card-body">
                                            <h4 className="tag-title">
                                                <i className="fa-solid fa-tag fs-4 me-2"></i>
                                                <span className="ms-1">FREIE JOURNALISTEN</span>
                                            </h4>
                                            <div className="tag-content">
                                                <p className="mb-0">
                                                    Nesem Sener Dogan,
                                                </p>
                                                <p className="mb-0">
                                                    Öznur Nizam,
                                                </p>
                                                <p className="mb-0">
                                                    Bahar Gökbender,
                                                </p>
                                                <p className="mb-0">
                                                    Onur Akkurt,
                                                </p>
                                                <p className="mb-0">
                                                    Meltem Pekcan
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card shadow-sm" style={{ borderRadius: "8px", display: `${Active === 7 ? "block" : "none"}` }}>
                                        <div className="card-body">
                                            <h4 className="tag-title">
                                                <i className="fa-solid fa-tag fs-4 me-2"></i>
                                                <span className="ms-1">INSERATE-ANNAHME</span>
                                            </h4>
                                            <div className="tag-content">
                                                <p className="mb-0">
                                                    anzeige@pusulamedia.ch
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card shadow-sm" style={{ borderRadius: "8px", display: `${Active === 8 ? "block" : "none"}` }}>
                                        <div className="card-body">
                                            <h4 className="tag-title">
                                                <i className="fa-solid fa-tag fs-4 me-2"></i>
                                                <span className="ms-1">GRAFIK – LAYOUT</span>
                                            </h4>
                                            <div className="tag-content">
                                                <p className="mb-0">
                                                    Halil Kale
                                                </p>
                                                <p className="mb-0">
                                                    Gökce Cankaya
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card shadow-sm" style={{ borderRadius: "8px", display: `${Active === 9 ? "block" : "none"}` }}>
                                        <div className="card-body">
                                            <h4 className="tag-title">
                                                <i className="fa-solid fa-tag fs-4 me-2"></i>
                                                <span className="ms-1">AUTOREN</span>
                                            </h4>
                                            <div className="tag-content">
                                                <p className="mb-0">
                                                    Derya Edis,
                                                </p>
                                                <p className="mb-0">
                                                    Hatice Yürütücü,
                                                </p>
                                                <p className="mb-0">
                                                    Dilan Calışkan Müller,
                                                </p>
                                                <p className="mb-0">
                                                    Hülya Rüst
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card shadow-sm" style={{ borderRadius: "8px", display: `${Active === 10 ? "block" : "none"}` }}>
                                        <div className="card-body">
                                            <h4 className="tag-title">
                                                <i className="fa-solid fa-tag fs-4 me-2"></i>
                                                <span className="ms-1">REDAKTIONSLEITERIN</span>
                                            </h4>
                                            <div className="tag-content">
                                                <p className="mb-0">
                                                    Derya Edis
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </div>
                    </section>

                </div>
            </section>
        </>
    )

}

export default Tags;