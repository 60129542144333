import axios from "axios";
import { Dates, Declare, Root, Urlgenerator } from "../libs/element";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';

function Detail({ categories, adsense }) {
    const { id, category } = useParams()
    const commentgo = useRef(null)
    const navigate = useNavigate()
    const location = useLocation()
    const [PostDetail, setPostDetail] = useState([])
    const [PostViews, setPostViews] = useState([])
    const [PostRecent, setPostRecent] = useState([])
    const Select = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        const postdetail = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post&conditions=post_id='${id}'`)).data
        const [postresponse] = await Promise.all([postdetail]);
        setPostDetail(postresponse.data)

        const postviews = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post_view&conditions=post_id='${id}'`)).data
        const [viewsresponse] = await Promise.all([postviews]);
        setPostViews(viewsresponse.data)

        const postrecent = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post&page=1&limit=6&conditions=category='${postresponse.data[0]["category"]}'AND post_id!='${id}'&order=created_date DESC`)).data;
        const [recentresponse] = await Promise.all([postrecent]);
        setPostRecent(recentresponse.data)
    }
    const Views = async (id) => {
        if (window.localStorage.getItem("ps-guest")) {
            const guest = JSON.parse(window.localStorage.getItem("ps-guest"))
            const postview = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post_view&conditions=post_id='${id}' AND user_id='${guest.guest_id}'`)).data
            const [viewresponse] = await Promise.all([postview]);
            if (viewresponse.data.length === 0) {
                axios.post(Root({ data: { type: "api" } }), {
                    params: "insert",
                    data: {
                        table: "post_view",
                        value: {
                            post_id: id,
                            user_id: guest.guest_id,
                        }
                    },
                })
            }
        }
        else {
            const user = JSON.parse(window.localStorage.getItem("ps-user"))
            const postview = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post_view&conditions=post_id='${id}' AND user_id='${user.user_id}'`)).data
            const [viewresponse] = await Promise.all([postview]);
            if (viewresponse.data.length === 0) {
                axios.post(Root({ data: { type: "api" } }), {
                    params: "insert",
                    data: {
                        table: "post_view",
                        value: {
                            post_id: id,
                            user_id: user.user_id,
                        }
                    },
                })
            }
        }
    }
    const Routers = (category, title, id) => {
        if (category !== undefined) {
            const result = categories.filter((f) => { return (f.id === category) })
            navigate(`/${Urlgenerator({ data: result[0]["category"] })}/${Urlgenerator({ data: title })}/${id}`)
        }
    }
    const sliderRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);
    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    const Video = ({ video }) => {
        if (JSON.parse(video)["video_data"]) {
            return (
                <>
                    <div className="d-sm-none d-lg-block">
                        <div className="mt-5 mb-2">
                            <div className="section-title section-title-four">
                                <div className="title">Haber videosu</div>
                                <div className="section-title-line"></div>
                            </div>
                        </div>
                        <iframe src={`https://www.youtube.com/embed/${JSON.parse(video)["video_data"]}`}></iframe>
                    </div>

                    <div className="d-sm-block d-lg-none">
                        <div className="mt-5 mb-2">
                            <div className="section-title section-title-four">
                                <div className="title">Haber videosu</div>
                                <div className="section-title-line"></div>
                            </div>
                        </div>
                        <iframe style={{ height: "200px", width: "100%", minHeight: "200px" }} src={`https://www.youtube.com/embed/${JSON.parse(video)["video_data"]}`}></iframe>
                    </div>
                </>
            )
        } else {
            return "";
        }
    }

    const galeryref = useRef(null)
    const handleGaleryPrev = useCallback(() => {
        if (!galeryref.current) return;
        galeryref.current.swiper.slidePrev();
    }, []);
    const handleGaleryNext = useCallback(() => {
        if (!galeryref.current) return;
        galeryref.current.swiper.slideNext();
    }, []);
    const [Modal, setModal] = useState(``)
    const Galery = ({ galery, response }) => {
        if (JSON.parse(JSON.parse(galery)["galery_image"]).length > 0) {
            const data = JSON.parse(JSON.parse(galery)["galery_image"]);

            return (
                <>
                    <div className="d-sm-none d-lg-block">
                        <div className="mt-5 mb-2">
                            <div className="section-title section-title-four">
                                <div className="title">Haber galerisi</div>
                                <div className="section-title-line"></div>
                            </div>
                        </div>
                        <div className="row m-0">
                            {
                                (data || []).map((s) => {
                                    return (
                                        <div className="col-lg-4 mb-2 p-2">
                                            <div className="ps-galery-image" onClick={() => { response(`${Root({ data: { type: "galery" } }) + s["blob"]}`) }} style={{ backgroundImage: `url(${Root({ data: { type: "galery" } }) + s["blob"]})` }}></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="d-sm-block d-lg-none">
                        <div className="mt-5 mb-2">
                            <div className="section-title section-title-four">
                                <div className="title">Haber galerisi</div>
                                <div className="section-title-line"></div>
                            </div>
                        </div>
                        <div className="row m-0">
                            {
                                (data || []).map((s) => {
                                    return (
                                        <div className="col-6 mb-2 p-2">
                                            <div className="ps-galery-image" onClick={() => { response(`${Root({ data: { type: "galery" } }) + s["blob"]}`) }} style={{ backgroundImage: `url(${Root({ data: { type: "galery" } }) + s["blob"]})`, height: "130px" }}></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </>
            )
        } else {
            return <></>
        }
    }
    const Recent = ({ show, spacer, title }) => {

        return (
            <>
                {title}
                <Swiper ref={sliderRef} loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }} slidesPerView={show} spaceBetween={spacer} className="slider-arrows-mini">
                    {
                        (PostRecent || []).slice(0, 6).map((d, x) => {
                            return (
                                <SwiperSlide key={`post-slide-${d.id}-list-${x}`}>
                                    <div className="ps-slider-container  cursor-pointer" onClick={() => { Routers(d.category, d.title, d.post_id) }}>
                                        <div className="ps-slider-image">
                                            <img src={`${Root({ data: { type: category === "yazarlar" ? "posts" : "posts" } })}${Declare({ data: { type: "image", data: d.media } })}`} alt={Declare({ data: { type: "image", data: d.media } })} />
                                        </div>
                                        <div className="ps-slider-content">
                                            <div className="ps-post-icon mb-3">
                                                {Dates({ data: d.created_date })}
                                            </div>
                                            <div className="ps-post-title">
                                                {d.title}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                    <div className="prev-arrow" onClick={handlePrev}>
                        <i className="fa-solid fa-angle-left text-white"></i>
                    </div>
                    <div className="next-arrow" onClick={handleNext}>
                        <i className="fa-solid fa-angle-right text-white"></i>
                    </div>
                </Swiper>
            </>
        )
    }
    const Highlight = ({ data, select }) => {
        if (JSON.parse(JSON.parse(data)["galery_image"]).length > 0) {
            const result = JSON.parse(JSON.parse(data)["galery_image"]);
            return (
                <>
                    <div className={`ps-highlight-modal ${Modal === `` ? "" : "active"}`} >
                        <div className="fs-highlight-container p-1">
                            <div className="text-end">
                                <i class="fa-solid fa-circle-xmark fs-close-icon" onClick={() => { setModal(``) }} ></i>
                            </div>
                            <Swiper ref={galeryref} loop={true} slidesPerView={1} className="slider-arrows">
                                {
                                    (result || []).map((d, x) => {
                                        return (
                                            <SwiperSlide key={`galery-slide-${d.id}-list-${x}`}>
                                                <img src={`${Root({ data: { type: "galery" } }) + d["blob"]}`} style={{ height: "auto", width: "100%" }} />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                                <div className="prev-arrow" onClick={handleGaleryPrev} style={{ top: "40%" }} >
                                    <i className="fa-solid fa-angle-left text-white"></i>
                                </div>
                                <div className="next-arrow" onClick={handleGaleryNext} style={{ top: "40%" }}>
                                    <i className="fa-solid fa-angle-right text-white"></i>
                                </div>

                            </Swiper>
                        </div>
                    </div >
                </>
            )
        } else {
            return <></>
        }

    }

    const GotoComment = () => {
        if (commentgo.current) {
            commentgo.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const Adsense = ({ page, position }) => {
        const result = (adsense || []).filter((f) => { return (f.page === page && f.position === position) })
        if (result.length > 0) {
            return JSON.parse(result[0].media)
        }
        else {
            return [];
        }
    }
    const [CommentData, setCommentData] = useState([])
    const GetComment = async () => {
        const comments = (await axios.get(`${Root({ data: { type: "api" } })}?params=select&table=post_comment&conditions=post_id='${id}'`)).data
        const [commentsresponse] = await Promise.all([comments]);
        setCommentData(commentsresponse.data)
    }
    useEffect(() => {
        Select()
        GetComment()
    }, [id])

    const [Comment, setComment] = useState("")
    const [Username, setUsername] = useState("")
    const [Email, setEmail] = useState("")
    const [Terms, setTerms] = useState(false)
    const SendComment = () => {

    }
    return (
        <>
            <div className=" mb-5">
                {/*Banner device-desktop*/}
                <section className="mb-4 d-sm-none d-lg-block">
                    <Swiper ref={sliderRef} loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }} className="slider-arrows-mini">
                        {
                            Adsense({ page: "detail", position: "content_1" }).map((d, x) => {
                                return (
                                    <SwiperSlide key={`banner-${d.position}-list-${x}`} style={{ height: "128.25px" }}>
                                        <div className="ps-post-banner">
                                            <a href={`${d.url}`} target="_blank">
                                                <img src={`${Root({ data: { type: "banner" } })}${d.blob}`} style={{ width: "100%", height: "auto" }} />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </section>
                {/*Detail */}
                <section>
                    {
                        (PostDetail || []).map((d, x) => {
                            return (
                                <div className="ps-post-detail" key={`post-detail-${d.id}-list-${x}`} onLoad={() => { Views(d.post_id) }}>
                                    <div className="ps-post-container cursor-pointer">
                                        <div className="ps-post-detail-title mb-4 d-sm-none d-lg-block">
                                            {d.title}
                                        </div>
                                        <div className="ps-post-detail-image mt-4 d-lg-none d-sm-block">
                                            <img src={`${Root({ data: { type: "posts" } })}${Declare({ data: { type: "image", data: d.media } })}`} alt={Declare({ data: { type: "image", data: d.media } })} />
                                        </div>
                                        <div className="ps-post-detail-info mt-4 d-sm-none d-lg-flex">
                                            <div className="me-4">
                                                <i className="fa-regular fa-circle-user me-1"></i> PUSULA SWISS
                                            </div>
                                            <div className="me-4">
                                                <i className="fa-regular fa-calendar-days me-1"></i>  {Dates({ data: d.created_date })}
                                            </div>
                                            <div className="me-4" onClick={() => { GotoComment() }} hidden>
                                                <i className="fa-regular fa-message me-1"></i>0 Yorum
                                            </div>
                                            <div>
                                                <i className="fa-regular fa-eye me-1"></i>{PostViews.length}  Görüntüleme
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center h-30px mb-2 mt-2 d-lg-none d-sm-flex">
                                            <div className="me-4">
                                                <i className="fa-regular fa-heart me-1 fs-14"></i>0
                                            </div>
                                            <div className="me-4">
                                                <i className="fa-regular fa-message me-1 fs-14"></i>0
                                            </div>
                                            <div className="me-4">
                                                <i className="fa-regular fa-eye me-1"></i>{PostViews.length}
                                            </div>
                                            <div className="me-4">
                                                <i className="fa-solid fa-share-nodes me-1 fs-14" ></i>
                                            </div>
                                        </div>
                                        <div className="ps-post-detail-title mb-1 d-lg-none d-sm-block">
                                            {d.title}
                                        </div>

                                        <div className="ps-post-detail-image mt-4 d-sm-none  d-lg-block">
                                            <img src={`${Root({ data: { type: category === "yazarlar" ? "posts" : "posts" } })}${Declare({ data: { type: "image", data: d.media } })}`} alt={Declare({ data: { type: "image", data: d.media } })} />
                                        </div>

                                        <div className="ps-post-detail-expert mt-2">
                                            <blockquote className="m-0">
                                                {d.description}
                                            </blockquote>
                                        </div>

                                        <div className="ps-post-detail-text mt-2">
                                            <div dangerouslySetInnerHTML={{ __html: d.content.replace(/<p>&nbsp;<\/p>/g, '') }}></div>
                                        </div>
                                        <div className="ps-post-detail-video mt-4">
                                            <Video video={d.media} device={"desktop"} />
                                        </div>
                                        <div className="ps-post-detail-galery mt-4">
                                            <Galery galery={d.media} device={"desktop"} response={(e) => { setModal(e); }} />
                                            <Highlight data={d.media} select={Modal} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </section>
                {/*Recent */}
                <section>
                    <div className="ps-post-detail-recent d-sm-none d-lg-block">
                        <Recent show={3} spacer={35} title={<div className="title">Bu Kategoriye Ait Son Haberler</div>} />
                    </div>
                    <div className="ps-post-detail-recent d-sm-block d-lg-none">
                        <Recent show={1} spacer={0} title={<div className="title fs-5 mb-2">Bu Kategoriye Ait Son Haberler</div>} />
                    </div>
                </section>
                {/*Banner */}
                <section ref={commentgo} className=" d-sm-none d-lg-block">
                    <Swiper ref={sliderRef} loop={true} modules={[Autoplay]} autoplay={{ delay: 2500 }} className="slider-arrows">
                        {
                            Adsense({ page: "detail", position: "content_2" }).map((d, x) => {
                                return (
                                    <SwiperSlide key={`banner-${d.position}-list-${x}`} style={{ height: "128.25px" }}>
                                        <div className="ps-post-banner">
                                            <a href={`${d.url}`} target="_blank">
                                                <img src={`${Root({ data: { type: "banner" } })}${d.blob}`} style={{ width: "100%", height: "auto" }} />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </section>

                {/*Comment */}
                <section hidden>
                    <div className="ps-post-comment mt-5">
                        <div className="row m-0">
                            {
                                CommentData.length === 0 ?
                                    <>
                                        <div className="col-lg-12 p-0">
                                            <div className="fs-6">Bu gönderiye, henüz yorum yapılmamış.</div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            (CommentData || []).map((d, x) => {
                                                return (
                                                    <>
                                                        <div className="ps-comment-item">
                                                            <div className="image">

                                                            </div>
                                                            <div className="content">
                                                                <div className="date"> </div>
                                                                <div className="user"> </div>
                                                                <div className="comment"> </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                            }
                            <div className="col-lg-12 mt-3 p-0">
                                <textarea className="ps-textarea" placeholder="Yorumunuz" rows={5} value={Comment} onChange={(e) => { setComment(e.target.value) }}></textarea>
                            </div>
                            <div className="col-lg-6 mt-2 p-0">
                                <input className="ps-input" placeholder="Adınız & Soyadınız" value={Username} onChange={(e) => { setUsername(e.target.value) }} />
                            </div>
                            <div className="col-lg-6 mt-2 p-0">
                                <input className="ps-input" placeholder="E-posta" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
                            </div>
                            <div className="col-lg-12 mt-2 p-0">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="checkbox" checked={Terms} onChange={(e) => { setTerms(e.target.checked); console.log(e.target.checked) }} />
                                    <label htmlFor="checkbox" className="text-dark fs-6">
                                        <a href="/belgeler/yorum-kurallari" target="_blank" className="checkbox-label" style={{ color: "#ec1c24" }}>Şartlar</a>ınızı Kabul Ediyorum
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-4 p-0">
                                <button type="button" className="btn ps-button" onClick={() => { SendComment() }}>
                                    Gönder
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Detail;