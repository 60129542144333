import axios from "axios"
import { useState } from "react"
import { Root } from "../libs/element"

function Contact() {

    const [User, setUser] = useState("")
    const [Email, setEmail] = useState("")
    const [Notes, setNotes] = useState("")
    const [Error, setError] = useState(0)

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const SendContact = () => {
        if (User === "") {
            setError(300)
        } else {
            if (Email === "") {
                setError(301)
            } else {
                if (Notes === "") {
                    setError(302)
                } else {
                    if (!validateEmail(Email)) {
                        setError(303)
                    }
                    else {
                        const companyBody = `
                        Name und Vorname: ${User}<br/>
                        E-Mail: ${Email}<br/>
                        Bemerkungen: ${Notes}<br/>
                        `
                        axios.post(Root({ data: { type: "api" } }), {
                            params: "email",
                            data: {
                                value: {
                                    email: "info@pusulaswiss.ch",
                                    title: "Pusula Swiss İletişim Formu",
                                    company: "Pusula Swiss",
                                    body: companyBody
                                }
                            },
                        }).then((data) => {
                            if (data.data.status) {
                                setError(400)
                            }
                        })
                    }
                }
            }
        }
    }

    return (
        <>
            <section className="contact-area">
                <div className="container">

                    <div className="row justify-content-center mt-2">
                        <div className="col-lg-6">
                            <div className="contact-info-item">
                                <div className="icon d-flex align-items-center">
                                    <i className="fa-solid fa-map-location-dot" style={{ fontSize: "32px" }}></i>
                                </div>
                                <div className="content">
                                    <h5 className="title mb-0">Adres</h5>
                                    <p className="mt-0" style={{lineHeight:"21px"}}>Pusula Media GmbH, Heimstrasse 10,8953 Dietikon</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="contact-info-item">
                                <div className="icon d-flex align-items-center">
                                    <i className="fa-solid fa-envelope-open" style={{ fontSize: "32px" }}></i>
                                </div>
                                <div className="content">
                                    <h5 className="title mb-0">E-posta</h5>
                                    <p className="mt-0">
                                        <a href="to:info@pusulamedia.ch" style={{ textDecoration: "none" }}>info@pusulamedia.ch</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="contact-info-item">
                                <div className="icon d-flex align-items-center">
                                    <i className="fa-solid fa-phone" style={{ fontSize: "32px" }}></i>
                                </div>
                                <div className="content">
                                    <h5 className="title mb-0">Telefon</h5>
                                    <p className="mt-0">
                                        <a href="tel:+41 (0) 44 576 60 80" style={{ textDecoration: "none" }}>+41 (0) 44 576 60 80</a>

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="ps-contanct-image" style={{ backgroundImage: `url(https://ik.imagekit.io/tey21emoa/Other/contact-image.webp)` }}></div>
                        </div>
                        <div className="col-lg-6 mt-2 p-0">
                            <div className="contact-form">
                                <div className="alert alert-danger" hidden={Error === 300 ? false : true}>
                                    Adınız & Soyadınız Boş Olamaz
                                </div>
                                <div className="alert alert-danger" hidden={Error === 301 ? false : true}>
                                    E-Posta Boş Olamaz
                                </div>
                                <div className="alert alert-danger" hidden={Error === 302 ? false : true}>
                                    Konu Boş Olamaz
                                </div>
                                <div className="alert alert-danger" hidden={Error === 303 ? false : true}>
                                    E-Posta Biçimi Hatalı
                                </div>
                                <div className="alert alert-success" hidden={Error === 400 ? false : true}>
                                    Mesajınız bize ulaştı. Konu ile ilgili sizinle kısa süre içinde iletişime geçeceğiz.
                                </div>
                                <div className="row">

                                    <div className="col-md-6 mb-3">
                                        <div className="form-grp">
                                            <input type="text" className="ps-form-control" name="name" placeholder="İsim & Soyisim*" value={User} onChange={(e) => { setUser(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-grp">
                                            <input type="email" className="ps-form-control" name="email" placeholder="E-posta*" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-grp mt-3">
                                    <textarea className="form-control" name="message" rows={10} placeholder="Mesajınız*" value={Notes} onChange={(e) => { setNotes(e.target.value) }}></textarea>
                                </div>
                                <button type="button" className="btn ps-button mt-3 mb-2" onClick={() => { SendContact() }}>
                                    Gönder
                                </button>
                                <p className="ajax-response mb-0"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="ps-contact-map mt-5">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d43201.85888483018!2d8.329226548632812!3d47.409675000000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900dacee710e77%3A0x4fee2ead2a8da7b9!2sPusula%20Media!5e0!3m2!1str!2str!4v1704202687949!5m2!1str!2str" allowFullScreen loading="lazy"></iframe>
            </div>
        </>
    )

}

export default Contact;