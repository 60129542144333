function Aboutus() {


    return (
        <>
            <div className="container p-0 mb-5">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <img src="https://ik.imagekit.io/tey21emoa/Other/pusula-swiss-mags.webp" style={{ width: "100%", height: "auto" }} />
                    </div>
                    <div className="col-lg-6">
                        <div className="ps-aboutus-title">Über uns</div>
                        <div className="ps-aboutus-text">
                            Seit der ersten Ausgabe im Jahr 2007 hat Pusula unsere Mitbürger stets begleitet und sich zum Ziel gesetzt, eine sichere Nachrichtenquelle zu sein. Zusätzlich zu unseren unverzichtbaren Grundsätzen wie Innovation und Entwicklung hat sich auch unser Verständnis für positive Berichterstattung von der ersten Ausgabe bis in die Gegenwart fortgesetzt.
                            Pusula vermittelt ihren Lesern die wichtigsten Nachrichten aus der Schweiz in einer unvoreingenommenen Sprache und versucht von Anfang an den Lesern die genauesten Informationen aus ihrer Quelle mit speziellen Interviews und Expertenmeinungen, sowie aktuellen Nachrichten zu übermitteln.
                        </div>
                        <div className="ps-aboutus-title">Ziel</div>
                        <div className="ps-aboutus-text">
                            Mit dem erneuerten Konzept und den bereicherten Inhalten wird Pusula ihren Lesern weiterhin wichtige Entwicklungen aus der Schweiz sowie alle notwendigen Informationen mit einem neuen Leitfaden vermitteln.
                            Unser oberstes Ziel ist die Integrationsförderung und das Näherbringen der neuen Heimat Schweiz. Wir wollen die ausländische Bevölkerung über Themen wie Politik, Sport, Gesundheit, Ausbildung, Wirtschaft und die Schweizer Geschichte informieren.
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

}

export default Aboutus;