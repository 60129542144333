import { useParams } from "react-router-dom"

function Create() {

    return (
        <>
            <div className="container mb-5 mt-4">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-5">
                        <div className="card rounded-1">
                            <div className="card-body">
                                <div className="ps-login-icon">
                                    <i className="fa-regular fa-circle-user me-2 ms-1"></i>
                                </div>
                                <div className="ps-login-title">
                                    Abone Ol
                                </div>
                                <div className="ps-login-text">
                                    Hesabımıza abone olarak içerik paylaşabilir, kendi haber akışınızı oluşturabilirsiniz.
                                </div>
                            </div>
                            <div className="card-body mt-2">
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <input className="form-control h-50px" placeholder="Kullanıcı Adı" />
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <input className="form-control h-50px" placeholder="Şifre" />
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <input className="form-control h-50px" placeholder="Şifre Tekrar" />
                                    </div>
                                    <div className="col-lg-12">
                                        <button type="button" className="btn ps-button" onClick={() => { }}>
                                            Abone Ol
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


function Singin() {

    return (
        <>
            <div className="container mb-5 mt-4">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-5">
                        <div className="card rounded-1">
                            <div className="card-body">
                                <div className="ps-login-icon">
                                    <i className="fa-solid fa-right-to-bracket"></i>
                                </div>
                                <div className="ps-login-title">
                                    Giriş Yapın
                                </div>
                                <div className="ps-login-text">
                                    Hesabınıza giriş yapın
                                </div>
                            </div>
                            <div className="card-body mt-2">
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <input className="form-control h-50px" placeholder="Kullanıcı Adı" />
                                    </div>
                                    <div className="col-lg-12 mb-1">
                                        <input className="form-control h-50px" placeholder="Şifre" />
                                    </div>
                                    <div className="col-lg-12 mb-4">
                                        <div className="form-check" >
                                            <input type="checkbox" className="form-check-input" id="checkbox" value="false" />
                                            <label htmlFor="checkbox" className="text-dark fs-6 cursor-pointer">Beni hatırla </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <button type="button" className="btn ps-button" onClick={() => { }}>
                                            Giriş Yap
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default function Login() {

    const { rules } = useParams()
    switch (rules) {
        case "hesap-olustur": return <Create />
        case "giris-yap": return <Singin />
        default: break;
    }

}